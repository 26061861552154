import React from 'react';

import SimpleService from './Common/SimpleService';

export default function CapterraReviews() {
  return (
    <SimpleService
      unitName='review'
      title='Capterra Reviews Scraper'
      startTaskButtonTitle='Export Reviews'
      subTitle='Returns reviews from a list of pages'
      serviceName='capterra_reviews'
      queriesPlaceholder='https://www.capterra.com/p/228041/Google-Maps-scraper/'
      apiTag='Capterra/paths/~1capterra-reviews/get'
      defaultParams={{ sort: 'MOST_HELPFUL' }}
      recentCutoffSorting={'MOST_RECENT'}
      sortingOptions={[
        { value: 'MOST_HELPFUL', text: 'Most Helpful' },
        { value: 'MOST_RECENT', text: 'Most Recent' },
        { value: 'HIGHEST_RATING', text: 'Higest Rating' },
        { value: 'LOWEST_RATING', text: 'Lowest Rating' },
      ]}
    />
  );
}
