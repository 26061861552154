import React from 'react';

import SimpleService from './Common/SimpleService';

export default function TripadvisorReviewsScraper() {
  return (
    <SimpleService
      unitName='review'
      title='Tripadvisor Reviews Scraper'
      subTitle='Returns reviews from a list of businesses'
      serviceName='tripadvisor_reviews_service_v3'
      queriesPlaceholder='https://www.tripadvisor.com/Restaurant_Review-g187147-d12947099-Reviews-Mayfair_Garden-Paris_Ile_de_France.html&#10;https://www.tripadvisor.com/Hotel_Review-g187147-d188729-Reviews-or40-Le_Bristol_Paris-Paris_Ile_de_France.html'
      learnMoreUrl='https://outscraper.com/tripadvisor-reviews-scraper'
      apiTag='Others/paths/~1tripadvisor~1reviews/get'
      defaultParams={{ sort: 'most_recent' }}
      recentCutoffSorting={'most_recent'}
      sortingOptions={[
        { value: 'most_recent', text: 'Most recent' },
      ]}
    />
  );
}
