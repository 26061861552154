import React from 'react';

import { services } from '../../../shared/data/services';
import SimpleService from '../Common/SimpleService';

export default function DisposableEmailChecker() {
  return (
    <SimpleService
      unitName='email'
      title='Disposable Email Checker'
      subTitle='Checks origins of email addresses (disposable, free, or corporate)'
      serviceName='disposable_email_checker'
      queriesPlaceholder='support@outscraper.com'
      queriesLabel='Email addresses'
      apiTag='Email-Related/paths/~1disposable-email-checker/get'
      limitType={null}
      enrichmentFields={services.disposable_email_checker.input_fields}
      supportedEnrichmentServices={[]}
    />
  );
}
