import React from 'react';

import SimpleService from './Common/SimpleService';

export default function FacebookSearchScraper() {
  return (
    <SimpleService
      unitName='page'
      title='Facebook Search Scraper (BETA)'
      subTitle='Scrape page links and names from FB search'
      queriesLabel='FB page search URLs (Make sure you are using locations filters as servers IPs might have different locations)'
      serviceName='facebook_search_service'
      queriesPlaceholder='https://www.facebook.com/search/pages?q=restaurants&filters=eyJmaWx0ZXJfcGFnZXNfbG9jYXRpb246MCI6IntcIm5hbWVcIjpcImZpbHRlcl9wYWdlc19sb2NhdGlvblwiLFwiYXJnc1wiOlwiMTA0MDIyOTI2MzAzNzU2XCJ9In0%3D'
    />
  );
}
