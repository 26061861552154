import React from 'react';

import { services } from '../../../shared/data/services';
import SimpleService from '../Common/SimpleService';

export default function USPhonesEnricher() {
  return (
    <SimpleService
      unitName='phone'
      title='US Phone Numbers Enricher'
      subTitle='Returns US phones carrier data (name/type), validates phones, ensures messages deliverability'
      serviceName='us_phones_enricher_service'
      queriesLabel='US phone numbers'
      queriesPlaceholder='1 281 236 8208&#10;1 281 236 2248'
      learnMoreUrl='https://outscraper.com/phone-number-lookup'
      limitType={null}
      enrichmentFields={services.phones_enricher_service.input_fields}
      supportedEnrichmentServices={[]}
    />
  );
}
