import React, { useState } from 'react';
import { Divider, Checkbox, Space } from 'antd';
import { Trans } from 'react-i18next';
import PropTypes from 'prop-types';

import { Filter, OpenLink, Params } from '../../../../../assets/icons';
import { PriceRange } from '../PriceRange/PriceRange';
import { RecommendedBadge } from '../RecommendedBadge/RecommendedBadge';
import './EnrichmentCard.scss';
import { FiltersModal } from '../FiltersModal/FiltersModal';
import { ParametersModal } from '../ParametersModal/ParametersModal';
import { getServiceUrl } from '../../../../../utils/utils';

export const EnrichmentCard = ({ task, updateTask, service, handleOnSelect, isSelected }) => {
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [isParametersModalOpen, setIsParametersModalOpen] = useState(false);

  const handleIconClick = (openModal) => {
    if (isSelected) {
      openModal(true);
    }
  };

  return (
    <div className='serviceCard'>
      <div className='header'>
        <Space className='title' align='center' justify='space-between' onClick={() => handleOnSelect(service.id)}>
          <Checkbox checked={isSelected} />
          <Trans i18nKey={`title.${service.name}`}>{service.name}</Trans>
        </Space>
        <Space className={`actions ${!isSelected ? 'disabled' : ''}`}>
          {service.hasFilters && task.service_name.startsWith('google_maps_service') && (
            <Filter
              className='icon'
              onClick={() => handleIconClick(setIsFilterModalOpen)}
            />
          )}
          {service.hasParameters && (
            <Params
              className='icon'
              onClick={() => handleIconClick(setIsParametersModalOpen)}
            />
          )}
        </Space>
      </div>

      <div className='content'>
        <div className='body'>
          <Trans i18nKey={`description.${service.id}`}>{service.description}</Trans>
        </div>

        <Divider className='divider'/>

        <div className='footer'>
          <PriceRange service={service}/>
          {service.isRecommended && <RecommendedBadge/>}
          <a href={getServiceUrl(service.id)} target='_blank' rel='noopener noreferrer'>
            <OpenLink className='icon'/>
          </a>
        </div>
      </div>

      <ParametersModal
        task={task}
        onChange={updateTask}
        isOpen={isParametersModalOpen}
        onClose={() => setIsParametersModalOpen(false)}
      />
      <FiltersModal
        task={task}
        onChange={updateTask}
        isOpen={isFilterModalOpen}
        onClose={() => setIsFilterModalOpen(false)}
      />
    </div>
  );
};

EnrichmentCard.propTypes = {
  task: PropTypes.object.isRequired,
  updateTask: PropTypes.func.isRequired,
  service: PropTypes.object.isRequired,
  handleOnSelect: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
};
