import React from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { Card } from 'antd';

const { Meta } = Card;

export default function Results({ value }) {
  const { emails = [], phones = [], socials = {}, site_data = {} } = value;
  const { title } = site_data;

  return (
    <Card
      style={{ marginTop: 15 }}
    >
      {title && <Meta title={title} />}<br/>

      {!isEmpty(emails) && <>
        <h5>Emails:</h5>
        {emails.map((el, i) => <div key={i}>
          <a href={`mailto:${el.value}`} target='_blank' rel='noopener noreferrer'>{el.value}</a>
          <br/>
        </div>)}
      </>}
      <br/>

      {!isEmpty(phones) && <>
        <h5>Phones:</h5>
        {phones.map((el, i) => <div key={i}>
          <a href={`callto:${el.value}`} target='_blank' rel='noopener noreferrer'>{el.value}</a>
          <br/>
        </div>)}
      </>}
      <br/>

      {!isEmpty(socials) && <>
        <h5>Social Links:</h5>
        {Object.keys(socials).map(key => <div key={key}>
          <a href={socials[key]} target='_blank' rel='noopener noreferrer'>{socials[key]}</a>
          <br/>
        </div>)}
      </>}
    </Card>
  );
}

Results.propTypes = {
  value: PropTypes.object.isRequired,
};
