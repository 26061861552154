import React from 'react';

import SimpleService from './Common/SimpleService';

export default function BookingReviewsScraper() {
  return (
    <SimpleService
      unitName='review'
      title='Booking Reviews Scraper'
      startTaskButtonTitle='Export Reviews'
      subTitle='Returns reviews from a list of hotels'
      serviceName='booking_reviews_service'
      queriesPlaceholder='https://www.booking.com/hotel/tr/old-town-point-amp-spa-antalya.html&#10;old-town-point-amp-spa-antalya'
      apiTag='Reviews-and-Comments/paths/~1booking~1reviews/get'
      defaultParams={{ sort: '' }}
      recentCutoffSorting={'f_recent_desc'}
      sortingOptions={[
        { value: '', text: 'Most Relevant' },
        { value: 'f_recent_desc', text: 'Newest First' },
        { value: 'f_recent_asc', text: 'Older First' },
        { value: 'f_score_desc', text: 'Highest Scores' },
        { value: 'f_score_asc', text: 'Lowest Scores' },
      ]}
    />
  );
}
