import React from 'react';
import { Form, InputNumber } from 'antd';

import SimpleService from '../Common/SimpleService';
import PreferredContactsItem from './../Common/PreferredContactsItem';

export default function HubSpotCompanyContactsFinder() {
  return (
    <SimpleService
      beta
      unitName='email'
      title='HubSpot Company Contacts Finder'
      subTitle='Find company contacts'
      serviceName='hubspot_contact_finder_service'
      startTaskButtonTitle='Find Contacts'
      limitType={null}
      showParams={false}
      isTaskReady={() => true}
      learnMoreUrl='https://outscraper.com/hubspot-enrichments/'
      requiredIntegratoion={'HubSpot'}
      defaultParams={{queries: 'contacts', integration_data_path: 'hubspot.refresh_token', limit_per_company: 3}}
      ExtraItems={({ updateTask, task }) => {
        const { limit_per_company } = task;

        return <>
          <PreferredContactsItem value={task} onChange={updateTask} isPaidUser />

          <Form.Item label='Number of contacts per one company' className='formItem'>
            <InputNumber
              min={1}
              step={1}
              max={100}
              value={limit_per_company}
              onChange={(v) => updateTask({ limit_per_company: parseInt(v) || 1 })}
            />
          </Form.Item><br/>

          * The service will try to find and add contact details for companies without associated contacts.
          <br/>
          <br/>
        </>;
      }}
    />
  );
}
