import apiClient from './apiClient';

export default {
  fetchTasks: (lastId = '', pageSize = 10, query = '') => apiClient.get('/tasks', { params: { lastId, pageSize, query } }),
  fetchSharedTask: (userId, taskId) => apiClient.get(`/shared-tasks/${userId}/${taskId}`),
  rerunTask: (taskId) => apiClient.put(`/tasks/${taskId}`),
  rerunTasks: (taskIds) => apiClient.put('/tasks', { task_ids: taskIds }),
  removeTasks: (taskIds) => apiClient.delete('/tasks', { data: { task_ids: taskIds } }),
  scheduleTask: (taskId, crontab, timezone = null) => apiClient.post(`/tasks/${taskId}/schedule`, { crontab, timezone }),
  fetchScheduledTasks: () => apiClient.get('/scheduled-tasks'),
  removeScheduledTask: (taskId) => apiClient.delete(`/scheduled-tasks/${taskId}`),
  pauseScheduledTask: (taskId) => apiClient.put(`/scheduled-tasks/${taskId}/pause`),
  resumeScheduledTask: (taskId) => apiClient.put(`/scheduled-tasks/${taskId}/resume`),
  fetchFinishedTasks: (pageSize = 25, skip = 0) => apiClient.get(`/requests?type=finished&pageSize=${pageSize}&skip=${skip}`),
  fetchWebhookCalls: (pageSize = 25, skip = 0) => apiClient.get(`/webhook-calls?pageSize=${pageSize}&skip=${skip}`),
};
