import React from 'react';

import { Avatar, Carousel, Typography } from 'antd';
import { UserOutlined, LinkedinFilled, FacebookFilled, TwitterOutlined, StarFilled } from '@ant-design/icons';

const { Title, Paragraph } = Typography;

const testimonials = [
  {
    name: 'Mihai Vinatoru',
    position: 'Managing Partner',
    avatar: 'https://outscraper.com/wp-content/uploads/2023/10/customer1.webp',
    testimonial: 'Outscraper provides fast and reliable data on Google Maps locations, in a simple and intuitive interface. The platform has advanced features, yet is user-friendly enough. In the last 12 months, we used Outscraper to extract relevant public data regarding 120,000+ locations.',
    link: 'https://www.linkedin.com/in/meetzah/',
    icon: <LinkedinFilled />,
  },
  {
    name: 'Juan G',
    position: 'Partner at Growth97',
    avatar: 'https://outscraper.com/wp-content/uploads/2023/10/client_81.webp',
    testimonial: 'As a Digital Marketing Consultancy, Outscraper has truly been a game-changer for both our business and our clients. It has allowed us to reach new potential clients and deliver breath-taking ROI campaigns for our clients. Anyone considering using Outscraper I would strongly recommend using it',
    link: 'https://www.linkedin.com/in/juan-gg/',
    icon: <LinkedinFilled />,
  },
  {
    name: 'Russell Hesse',
    position: 'CMO',
    avatar: 'https://outscraper.com/wp-content/uploads/2020/02/Russell.webp',
    testimonial: 'What an awesome tool for getting marketing lead prospects. It\'s much faster than the estimates they give when doing a scrape. (It\'s always good to under-promise & over-deliver to your customers)...',
    link: 'https://www.trustpilot.com/reviews/6493cca93a6a771dfa373e0d',
    icon: <StarFilled />,
  },
  {
    name: 'Travis Howell',
    position: 'Researcher',
    avatar: 'https://outscraper.com/wp-content/uploads/2023/10/customer2.webp',
    testimonial: 'Outscraper has been much easier to use than other alternatives, and it has provided me with some great information. Their customer service has been helpful too when I\'ve needed it.',
    link: 'https://www.linkedin.com/in/travis-howell-67900156/',
    icon: <LinkedinFilled />,
  },
  {
    name: 'Silvia',
    position: 'S1-BRANDING Universitas Prasetiya Mulya',
    avatar: 'https://outscraper.com/wp-content/uploads/2023/10/customer5.webp',
    testimonial: 'Thank you very much for your reply and help! If you don\'t mind please deliver my thanks to the developer team. I hope you all thrive!',
    link: 'https://www.facebook.com/outscraper/reviews/',
    icon: <FacebookFilled />,
  },
  {
    name: 'Piyush',
    position: 'CEO',
    avatar: 'https://outscraper.com/wp-content/uploads/2023/10/client6.webp',
    testimonial: 'Thank you so much for providing a brilliant platform “Outscraper” to find a huge amount of data in a few minutes, also, thanks for boosting my sales',
    link: 'https://www.facebook.com/outscraper/reviews/',
    icon: <FacebookFilled />,
  },
  {
    name: 'Nate Lile',
    position: 'Entrepreneur',
    avatar: 'https://outscraper.com/wp-content/uploads/2023/10/testimonial6.webp',
    testimonial: 'I\'ve been testing and using Outscraper for a few weeks now. I\'m incredibly happy with their current offerings and I\'m looking forward to them adding more public sources. It\'s stupid easy to use and so affordable. Thanks guys!',
    link: 'https://www.facebook.com/outscraper/reviews/',
    icon: <FacebookFilled />,
  },
  {
    name: 'Ashish Kumar',
    position: 'Data analyst and front-end designer',
    avatar: 'https://outscraper.com/wp-content/uploads/2023/10/testimonial5.webp',
    testimonial: 'This service has been extremely helpful for me. Apart from their super fast API for various services, their amazing support is something that would make you go back to them. Highly recommended!',
    link: 'https://www.producthunt.com/posts/outscraper'
  },
  {
    name: 'Dion Kenney',
    position: 'COO of Mondofora',
    avatar: 'https://outscraper.com/wp-content/uploads/2023/10/client_81.webp',
    testimonial: 'I wish I had found it sooner.  I\'ve been manually collecting data from Google Maps for the past 15 months! The first search produced more usable data in 20 minutes than my 15 months did altogether.',
    link: 'https://twitter.com/mondofora',
    icon: <TwitterOutlined />,
  },
  {
    name: 'Ian Mason',
    position: 'CTO',
    avatar: 'https://outscraper.com/wp-content/uploads/2020/02/Lan.webp',
    testimonial: 'Outscraper is the best scraper I\'ve found. I cannot believe how well it works, how much data I get back, with no errors or headaches. I can make any directory site I want now',
    link: 'https://www.trustpilot.com/reviews/64d24d6f30844116cc798b25',
    icon: <StarFilled />,
  }
];

export default function Testimonials() {
  return <>
    <Carousel
      autoplay
      autoplaySpeed={6000}
      style={{ maxWidth: 235, margin: 'auto' }}
    >
      {testimonials.map((testimonial) => (
        <div style={{ textAlign: 'center' }} key={testimonial.name}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <a href={testimonial.link} target='_blank' rel='noopener noreferrer'>
              <Avatar
                size={64}
                icon={<UserOutlined />}
                src={testimonial.avatar}
                style={{ marginBottom: '12px', marginTop: '4px' }}
              />
            </a>
          </div>
          <div style={{ textAlign: 'center' }}>
            <Title level={4} style={{ marginBottom: 0, display: 'inline-block' }}>{testimonial.name}</Title>
            <a href={testimonial.link} target='_blank' rel='noopener noreferrer' style={{ marginLeft: '8px' }}>{testimonial.icon}</a>
            <Paragraph italic style={{ marginTop: '4px' }}>{testimonial.position}</Paragraph>
          </div>
          <Paragraph italic style={{ marginTop: '12px', marginBottom: '32px', marginLeft: '4px' }}>{testimonial.testimonial}</Paragraph>
        </div>
      ))}
    </Carousel>
  </>;
}
