import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCrown } from '@fortawesome/free-solid-svg-icons';

export default function PremiumFeatureIcon({ size = 'sm' }) {
  return <FontAwesomeIcon size={size} icon={faCrown} color='#fbbe28'/>;
}

PremiumFeatureIcon.propTypes = {
  size: PropTypes.string,
};
