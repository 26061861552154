import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

export default function PrivateRoute({ component: Component, path, location, auth, ...rest }) {
  const { authenticated = false } = auth;
  const { pathname, search } = location;

  useEffect(() => {
    if (authenticated) {
      return;
    }

    const fn = async () => {
      window.location = `/login?redirect=${pathname}${search}`;
    };

    fn();

  }, [auth, path]);

  const render = props => authenticated === true ? <Component {...props} {...rest} /> : null;

  return (
    <Route path={path} render={render} {...rest} />
  );
}

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  path: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};
