import config from 'react-global-configuration';
import prod from './prod';
import local from './local';

if (window.location.hostname === 'localhost') {
  config.set(prod, { freeze: false });
  config.set(local, { assign: true });
} else {
  config.set(local, { freeze: false });
  config.set(prod, { assign: true });
}
