import React from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { Form, Select, Checkbox, Row, Col } from 'antd';
import { useTranslation, Trans } from 'react-i18next';

import { toTitleCase } from '../../../utils/utils';
import LocationsSelect from './Selects/LocationsSelect';
import CustomItemsSelect from './Selects/CustomItemsSelect';
import CustomQueries from './Selects/CustomQueries';
import plainQueriesSwitchFormHeader from './Google/plainQueriesSwitchFormHeader';

const { Option } = Select;

const formStyle = { maxWidth: 800 };
const yelpCategories = [
  'restaurants',
  'shopping',
  'nightlife',
  'bars',
  'beauty & spas',
  'health & medical',
  'home services',
  'automotive',
  'local services',
  'event planning & services',
  'hotels & travel',
  'pets',
  'arts & entertainment',
  'fitness & instruction',
  'real estate',
  'active life',
  'education',
  'financial services',
  'public services & government',
  'professional services'
];

export default function YelpQueriesFormItems({
  task, onUpdate, isCustomQueries, isCustomLocations,
  onIsCustomLocationsChange, customQueriesKey, enrichmentFields, singleRegion,
}) {
  const { queries, categories, customLocations, input_file, tags = [], settings } = task;

  const { t } = useTranslation();

  function onQueriesChange(queries) {
    onUpdate({ queries });
  }

  function getAutoTag(categories) {
    if (isEmpty(categories)) return null;

    let tag = `${categories[0]}`;
    if (categories.length > 1) tag += ` +${categories.length - 1}`;

    return tag;
  }

  function onCategoriesChange(newCategories) {
    const payload = { categories: newCategories };

    if (isEmpty(tags) || tags[0] === getAutoTag(categories)) {
      const autoTag = getAutoTag(newCategories);

      if (autoTag) {
        if (isEmpty(tags)) {
          payload.tags = [autoTag];
        } else {
          const newTags = [...tags];
          newTags[0] = autoTag;
          payload.tags = newTags;
        }
      } else {
        if (tags.length === 1) {
          payload.tags = [];
        } else {
          const newTags = [...tags];
          newTags.slice(1);
          payload.tags = newTags;
        }
      }
    }

    onUpdate(payload);
  }

  function onCustomLocationsChange(customLocations) {
    onUpdate({ customLocations });
  }

  function onFileUploaded(input_file, enrich, output_extension) {
    onUpdate({ input_file, enrich, queries: '', settings: { ...settings, output_extension } });
  }

  function handleOnIsCustomLocationsChange() {
    const value = !isCustomLocations;
    onIsCustomLocationsChange(value);
  }

  return <>
    {isCustomQueries ? (
      <Form.Item
        required
        className='queries-input'
        label={<Row className='w-100' justify='space-between' align='middle'>
          <Col>
          Queries (URLs or categories + locations separated by vertical bar ` | `)
          </Col>
          <Col>
            {plainQueriesSwitchFormHeader(task, onUpdate, customQueriesKey, true)}
          </Col>
        </Row>}
        style={formStyle}
      >
        <CustomQueries
          value={queries}
          onChange={onQueriesChange}
          inputFile={input_file}
          onFileUploaded={onFileUploaded}
          placeholder='https://www.yelp.com/search?cflt=restaurants&find_loc=San+Francisco%2C+CA&#10;https://www.yelp.com/search?cflt=Plumbing&find_loc=San+Francisco%2C+CA&#10;Auto Repair | San Mateo, CA'
          enrichmentFields={enrichmentFields}
        />
      </Form.Item>
    ) : (
      <>
        <Form.Item
          required
          className='categories-select'
          label={<Row className='w-100' justify='space-between' align='middle'>
            <Col>
              <Trans i18nKey='title.categories'>Categories/brands</Trans>
            </Col>
            <Col>
              {plainQueriesSwitchFormHeader(task, onUpdate, customQueriesKey, true)}
            </Col>
          </Row>}
          style={formStyle}
        >
          <Select
            allowClear
            autoFocus
            showSearch
            autoClearSearchValue={false}
            size='large'
            mode={'tags'}
            maxTagCount={4}
            style={{ maxWidth: 760 }}
            tokenSeparators={[',', '\n']}
            placeholder={t('action.selectCategories', 'Select categories')}
            value={categories}
            onChange={onCategoriesChange}
          >{yelpCategories.map(c => <Option key={c} value={c}>{toTitleCase(c)}</Option>)}</Select>
        </Form.Item>

        <Form.Item
          required
          className='locations-select'
          label={<Trans i18nKey='title.locations'>Locations</Trans>}
          style={formStyle}
        >
          {isCustomLocations ? <>
            <CustomItemsSelect
              placeholder='New York, NY&#10;90001, Los Angeles, LA'
              value={customLocations}
              onChange={onCustomLocationsChange}/>
          </> : <LocationsSelect task={task} onUpdate={onUpdate} singleRegion={singleRegion} />}

          <Form.Item className='formItem2'>
            <Form.Item className='inlineFormItem3'>
              <Checkbox
                checked={isCustomLocations}
                onChange={handleOnIsCustomLocationsChange}
              ><Trans i18nKey='title.customLocations'>Custom locations</Trans></Checkbox>
            </Form.Item>
          </Form.Item>
        </Form.Item>
      </>
    )}
  </>;
}

YelpQueriesFormItems.propTypes = {
  task: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  isCustomQueries: PropTypes.bool.isRequired,
  isCustomCategories: PropTypes.bool.isRequired,
  isCustomLocations: PropTypes.bool.isRequired,
  onIsCustomCategoriesChange: PropTypes.func.isRequired,
  onIsCustomLocationsChange: PropTypes.func.isRequired,
  extraHint: PropTypes.string,
  customQueriesKey: PropTypes.string,
  enrichmentFields: PropTypes.array,
  singleRegion: PropTypes.string,
};
