import React from 'react';

import GoogleSearchBase from './Base';

export default function GoogleSearchShopping() {
  return (
    <GoogleSearchBase
      title='Google Search Shopping Scraper'
      subTitle='Returns search results'
      serviceName='google_search_shopping_service'
      queriesPlaceholder='Iphone 13&#10;Dell monitor&#10;Gold eagle coin'
      apiTag='Google/paths/~1google-search-shopping/get'
    />
  );
}
