import React from 'react';

import SimpleService from './Common/SimpleService';

export default function TwitterProfilesScraper() {
  return (
    <SimpleService
      limitType={null}
      unitName='page'
      title='Twitter Profiles Scraper'
      subTitle='Returns data from a list of profiles'
      queriesLabel='Usernames, pages URLs'
      serviceName='twitter_profiles'
      queriesPlaceholder='outscraper&#10;https://twitter.com/outscraper'
      apiTag='Others/paths/~1twitter~1profiles/get'
    />
  );
}
