import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Popover } from 'antd';
import config from 'react-global-configuration';

import DownloadableTaskResult from './DownloadableTaskResult';

const { Text } = Typography;
const taskExpiredAfter = config.get('taskExpiredAfter');

export default function TaskResults({ showEmptyTaskInfo, value = [], created, size = 'small', showInfo = true, type = 'default' }) {
  const expired = Date.now() - Date.parse(created) > taskExpiredAfter * 60 * 60 * 24 * 1000;
  const valueArray = value ? value : [];
  const downloadableResults = valueArray.length > 1 ? valueArray.filter(r => r.file_url) : valueArray;
  const totalUsage = valueArray.reduce((a, b) => a + b.quantity, 0);

  function onShowEmptyTaskInfo() {
    showEmptyTaskInfo(downloadableResults.length > 0 ? downloadableResults[0] : {});
  }

  return <span>
    {expired ?
      <Text type='secondary'>Expired</Text> :
      <Popover
        content={showInfo ? <>
          {valueArray.map((result, i) => <div key={`result-${i}`}>
            <strong>{result.product_name}</strong> - usage: {result.quantity}{result.ignored ? `, ignored: ${result.ignored} (ignored by exact match filter, other filters, duplicates, etc.)` : ''}
          </div>)
          }
          {!totalUsage && showEmptyTaskInfo && <>
            <br/>
            <>Task returned no results. <a onClick={onShowEmptyTaskInfo}>Why did it happen?</a></>
          </>}
        </> : null}
      >
        {downloadableResults.map((result, i) =>
          <DownloadableTaskResult key={`result-${i}`} value={result} size={size} type={type} />
        )}
      </Popover>
    }
  </span>;
}

TaskResults.propTypes = {
  showEmptyTaskInfo: PropTypes.func,
  value: PropTypes.array,
  created: PropTypes.string,
  size: PropTypes.string,
  showInfo: PropTypes.bool,
  type: PropTypes.string,
};
