import React from 'react';

import SimpleService from './Common/SimpleService';

export default function Linkedin() {
  return (
    <SimpleService
      unitName='review'
      title='Linkedin Scraper'
      subTitle='Returns profiles from Linkedin'
      serviceName='linkedin_service'
      queriesPlaceholder='https://www.linkedin.com/search/results/people/?geoUrn=["103644278"]&keywords="node.js" AND "senior"'
      queriesLabel='URLs to linkedin people search'
      apiTag='Others/paths/~1linkedin~1profiles/get'
    />
  );
}
