import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import qs from 'query-string';
import { Button, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';

import history from '../../utils/history';
import TasksTable from './TasksTable';
import ScheduledTasksTable from './ScheduledTasksTable';

export default function Tasks({ location }) {
  const { t } = useTranslation();
  const { type: urlType = 'tasks' } = qs.parse(location.search);
  const loading = useSelector(state => state.tasksReducer.loading);
  const [type, setType] = useState(urlType);
  const [refresh, doRefresh] = useState(0);

  function onTabChange(value) {
    setType(value);
    history.push({ search: value !== 'tasks' ? qs.stringify({ type: value }) : '' });
  }

  return (
    <Tabs
      tabBarExtraContent={
        <Button
          type='default'
          style={{ float: 'right' }}
          loading={loading}
          onClick={() => doRefresh(prev => prev + 1)}
        >{t('title.refresh', 'Refresh')}</Button>
      }
      defaultActiveKey={type}
      onChange={onTabChange}
      items={[
        {key: 'tasks', label: t('title.tasks', 'Tasks'), children: <TasksTable selected={type === 'tasks'} refresh={refresh} />},
        {key: 'scheduled', label: t('title.scheduled', 'Scheduled'), children: <ScheduledTasksTable selected={type === 'scheduled'} refresh={refresh} />}
      ]}
    />
  );
}

Tasks.propTypes = {
  location: PropTypes.object.isRequired,
};
