import React from 'react';

import GoogleSearchBase from './Base';

export default function GoogleSearchVideos() {
  return (
    <GoogleSearchBase
      isGoogleLocationSelect
      title='Google Search Videos Scraper'
      subTitle='Returns search results'
      serviceName='google_search_videos_service'
      queriesPlaceholder='Bitcoin&#10;Outscraper'
      apiTag='Google/paths/~1google-search-videos/get'
    />
  );
}
