import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-locize-backend';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(Backend)
  .init({
    fallbackLng: 'en',
    saveMissing: true,
    backend: {
      projectId: 'c0698fca-5058-4004-a726-67a6079fca3d',
      apiKey: '8cece7b9-aaa6-4320-8636-e97930f23db3',
    }
  });

export default i18n;
