import React from 'react';
import { notification } from 'antd';

import TaskSent from '../../Components/Messages/TaskSent';
import apiClient from '../../api/apiClient';
import Mixpanel from '../../analytics/mixpanel';

export const START_REQUEST = 'START_QUERIES_REQUEST';
export const STOP_REQUEST = 'STOP_QUERIES_REQUEST';
export const FETCH_LOCATIONS_SUCCESS = 'FETCH_LOCATIONS_SUCCESS';
export const VALIDATE_TASK_SUCCESS = 'VALIDATE_TASK_SUCCESS';

export const startRequest = () => ({ type: START_REQUEST });
export const stopRequest = () => ({ type: STOP_REQUEST });

function transformData(data, parent) {
  return data.map(node => ({ title: (node.t || node.v), value: parent + '>' + node.v, children: node.c ? transformData(node.c, parent + '>' + node.v) : undefined }));
}

export const sendExtractionTask = (task) => async (dispatch) => {
  let isFirstTask = false;

  dispatch(startRequest());
  try {
    const { data } = await apiClient.post('/tasks', task);
    const { is_first_task } = data;
    isFirstTask = is_first_task;

    notification.success({
      message: 'Sent',
      description: <TaskSent />,
      duration: 8, style: { width: 600 }
    });
  } catch (error) {
    notification.error({ message: error.message, duration: 8 });
  } finally {
    dispatch(stopRequest());
  }

  return isFirstTask;
};

export const validateExtractionTask = (task) => async (dispatch) => {
  dispatch(startRequest());
  try {
    const { data } = await apiClient.post('/validate_task', task);

    dispatch({ taskValidationResult: data, type: VALIDATE_TASK_SUCCESS });
  } catch (error) {
    notification.error({ message: error.message });
  }
  dispatch(stopRequest());

  Mixpanel.track('Validate task', { task });
};

export const fetchLocations = (country) => async (dispatch, getState) => {
  try {
    dispatch(startRequest());

    const { countryLocations, countrySupportsZipCodes } = getState().queriesReducer;
    if (country in countryLocations) {
      return countryLocations[country];
    }

    const { data } = await apiClient.get('/locations', { params: { country, v: 2 } });
    dispatch({
      countryLocations: { ...countryLocations, [country]: transformData(data.items, country) },
      countrySupportsZipCodes:  { ...countrySupportsZipCodes, [country]: data.supports_zip_codes },
      type: FETCH_LOCATIONS_SUCCESS
    });
  } catch (error) {
    notification.error({ message: error.message });
  } finally {
    dispatch(stopRequest());
  }
};
