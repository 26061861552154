import React from 'react';

import GoogleSearchBase from './Base';

export default function GoogleSearchCareers() {
  return (
    <GoogleSearchBase
      title='Google Search Careers'
      subTitle='Returns search results'
      queriesLabel='Links to Google Careers searches'
      serviceName='google_careers_service'
      apiTag='Google/paths/~1google-search-careers/get'
      queriesPlaceholder='https://careers.google.com/jobs/results/?distance=50&has_remote=true&location=Los%20Angeles,%20CA,%20USA'
      learnMoreUrl='https://outscraper.com/google-careers-scraper/'
      maxLimitPagesPerQuery={1000}
      showLanguageAndRegion={false}
    />
  );
}
