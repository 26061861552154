import React from 'react';
import { Form, Input } from 'antd';

import SimpleService from './Common/SimpleService';

export default function AIScraper() {
  return (
    <SimpleService
      beta
      unitName='page'
      title='AI Scraper'
      serviceName='ai_scraper_service'
      queriesLabel='Links to pages'
      queriesPlaceholder='https://www.mechmods.co.uk/products/gb-opus-1-keyboard-kit'
      tutorialLink='https://outscraper.com/ai-data-scraper-tutorial/'
      limitType={null}
      defaultParams={{
        instruction: 'Please generate JSON with the following attributes, name, price and description'
      }}
      ExtraItems={({ updateTask, task }) => {
        function handleOnChange({ target }) {
          updateTask({ instruction: target.value } );
        }

        return <>
          <Form.Item required label='Instructions for GPT'>
            <Input.TextArea
              rows={5}
              style={{ width: 800 }}
              value={task.instruction}
              onChange={handleOnChange}
              placeholder='Please generate JSON with the following attributes, name, price and description'
            />
          </Form.Item>
        </>;
      }}
    />
  );
}
