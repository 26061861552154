import React from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'antd';
import { useTranslation } from 'react-i18next';

const defaultHeader = 'Advanced parameters';

export default function AdvancedParametersPanel({ children, header }) {
  const { t } = useTranslation();

  if (!header) {
    header = t(`title.${defaultHeader}`, defaultHeader);
  }

  const items = [
    {
      key: 'advancedParameters',
      label: header,
      children: <>{children}</>
    }
  ];

  return (
    <>
      <Collapse ghost items={items} />
    </>
  );
}

AdvancedParametersPanel.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
  className: PropTypes.string
};
