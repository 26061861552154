import {
  START_REQUEST,
  STOP_REQUEST,
  FETCH_LOCATIONS_SUCCESS,
  VALIDATE_TASK_SUCCESS,
} from '../actions/queriesActions';

export default (state = { categories: [], countryLocations: {}, countrySupportsZipCodes: {}, taskValidationResult: {} }, action) => {
  switch (action.type) {
  case START_REQUEST: {
    return {
      ...state,
      loading: true,
    };
  }
  case STOP_REQUEST: {
    return {
      ...state,
      loading: false,
    };
  }
  case FETCH_LOCATIONS_SUCCESS: {
    return {
      ...state,
      countryLocations: action.countryLocations,
      countrySupportsZipCodes: action.countrySupportsZipCodes,
    };
  }
  case VALIDATE_TASK_SUCCESS: {
    return {
      ...state,
      taskValidationResult: action.taskValidationResult,
    };
  }
  default:
    return state;
  }
};
