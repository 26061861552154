import React from 'react';
import PropTypes from 'prop-types';
import { Form, Select } from 'antd';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

export default function DomainSelectItem({ value, onChange }) {
  const { t } = useTranslation();

  return <Form.Item label={t('title.domain', 'Domain')} className='formItem'>
    <Select
      showSearch
      style={{ width: 200 }}
      value={value}
      onChange={onChange}
    >
      <Option key='amazon.com' value='amazon.com'>amazon.com</Option>
      <Option key='amazon.co.uk' value='amazon.co.uk'>amazon.co.uk</Option>
      <Option key='amazon.ca' value='amazon.ca'>amazon.ca</Option>
      <Option key='amazon.de' value='amazon.de'>amazon.de</Option>
      <Option key='amazon.es' value='amazon.es'>amazon.es</Option>
      <Option key='amazon.fr' value='amazon.fr'>amazon.fr</Option>
      <Option key='amazon.it' value='amazon.it'>amazon.it</Option>
      <Option key='amazon.in' value='amazon.in'>amazon.in</Option>
      <Option key='amazon.nl' value='amazon.nl'>amazon.nl</Option>
      <Option key='amazon.se' value='amazon.se'>amazon.se</Option>
      <Option key='amazon.sa' value='amazon.sa'>amazon.sa</Option>
      <Option key='amazon.com.mx' value='amazon.com.mx'>amazon.com.mx</Option>
      <Option key='amazon.com.br' value='amazon.com.br'>amazon.com.br</Option>
      <Option key='amazon.co.jp' value='amazon.co.jp'>amazon.co.jp</Option>
      <Option key='amazon.pl' value='amazon.pl'>amazon.pl</Option>
      <Option key='amazon.com.au' value='amazon.com.au'>amazon.com.au</Option>
      <Option key='amazon.ae' value='amazon.ae'>amazon.ae</Option>
    </Select>
  </Form.Item>;
}

DomainSelectItem.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
