import React from 'react';

import SimpleService from './Common/SimpleService';

export default function FacebookPagesScraper() {
  return (
    <SimpleService
      limitType={null}
      unitName='page'
      title='Facebook Pages Scraper'
      subTitle='Returns data from a list of pages'
      queriesLabel='URLs to FB pages, pages IDs'
      serviceName='facebook_pages_service_v2'
      queriesPlaceholder='Protegepaloalto&#10;https://www.facebook.com/moodswinebar/&#10;https://www.facebook.com/pages/Siam-Royal-Authentic-Thai-Cuisine/108802532485636'
      apiTag='Facebook/paths/~1facebook~1pages/get'
      supportedEnrichmentServices={['emails_validator_service', 'disposable_email_checker', 'phones_enricher_service', 'whatsapp_checker']}
    />
  );
}
