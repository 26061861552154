import React from 'react';
import PropTypes from 'prop-types';
import { Popover, Button, Tag } from 'antd';
import Moment from 'react-moment';
import Href from '../../shared/Components/Href';

export default function InvoiceStatus({ invoice = {} }) {
  const { status, created, hosted_invoice_url } = invoice;
  const scheduled = status === 'scheduled';
  const color = getColor(status);

  function getColor(status) {
    if (status === 'paid' || status === 'void') {
      return 'green';
    }
    else if (status === 'draft' || status === 'scheduled') {
      return null;
    }
    else {
      return 'red';
    }
  }

  return <>
    <Popover open={scheduled ? undefined : false} content={scheduled ? (
      <>This draft invoice will be finalized <Moment unix local fromNow>{created + (60 * 60)}</Moment>.</>
    ) : null}>
      <Tag color={color}>{status}</Tag>
    </Popover>
    {color === 'red' && <Href href={hosted_invoice_url} external><Button type='primary' size='small'>Pay</Button></Href> }
  </>;
}

InvoiceStatus.propTypes = {
  invoice: PropTypes.object.isRequired,
};
