import React from 'react';
import PropTypes from 'prop-types';
import { Card, Descriptions, Badge } from 'antd';

// TODO
export default function Results({ value }) {
  const { status, status_details, query } = value;

  return (
    <Card style={{ marginTop: 15 }}>
      <Descriptions>
        <Descriptions.Item label='Email' span={2}>{query}</Descriptions.Item>
        <Descriptions.Item label='Status' span={1}>
          {status === 'RECEIVING' ? <Badge status='success' text={status} /> : <Badge status='error' text={status} />}
        </Descriptions.Item>
        <Descriptions.Item label='Details' span={3}>{status_details}</Descriptions.Item>
      </Descriptions>
    </Card>
  );
}

Results.propTypes = {
  value: PropTypes.object.isRequired,
};
