import React from 'react';

import GoogleSearchBase from './Base';

export default function GoogleSearchJobs() {
  return (
    <GoogleSearchBase
      title='Google Search Jobs'
      subTitle='Returns search results'
      serviceName='google_search_jobs_service'
      apiTag='Google/paths/~1google-search-jobs/get'
      queriesPlaceholder='Python developer California&#10;UI/UX designer New York'
      learnMoreUrl='https://outscraper.com/google-jobs-scraper'
      maxLimitPagesPerQuery={50}
    />
  );
}
