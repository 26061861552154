import React from 'react';

import SimpleService from './Common/SimpleService';

export default function YelpPhotosScraper() {
  return (
    <SimpleService
      unitName='photo'
      title='Y.E.L.P Photos Scraper'
      subTitle='Returns photos from a list of businesses'
      serviceName='yelp_photos'
      queriesPlaceholder='https://www.yelp.com/biz/cancha-boutique-gastrobar-san-francisco&#10;eggcellent-waffles-san-francisco&#10;iXoLJWjbcXCO43RT-H0uQQ'
    />
  );
}
