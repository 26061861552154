import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import styled from 'styled-components';
import { DownloadOutlined } from '@ant-design/icons';

const StyledButton = styled(Button)`
  margin: 0 5px;
`;

export default function DownloadableTaskResult({ value = {}, size = 'small', type = 'default' }) {
  const { product_name, quantity, file_url } = value;
  const totalUsage = quantity;
  const isUsage = !!totalUsage && totalUsage > 0;

  return <StyledButton
    shape='round'
    type={type}
    size={size}
    title='Download the result'
    disabled={!isUsage}
    href={file_url}
    icon={<DownloadOutlined/>}
  >
    <>{product_name}</>{isUsage && <>&nbsp;[{totalUsage}]</>}
  </StyledButton>;
}

DownloadableTaskResult.propTypes = {
  value: PropTypes.object,
  size: PropTypes.string,
  type: PropTypes.string,
};
