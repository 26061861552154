import React from 'react';
import PropTypes from 'prop-types';

import { Button, Input, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

export default function SearchFilterDropdown({ searchInput, selectedKeys, setSelectedKeys, onSearch, onSearchReset, clearFilters, confirm }) {
  return (
    <div style={{ padding: 8 }}>
      <Input
        ref={searchInput}
        placeholder={'Search by tag'}
        value={selectedKeys}
        onChange={e => setSelectedKeys(e.target.value)}
        onPressEnter={() => onSearch(selectedKeys, confirm)}
        style={{ width: 230, marginBottom: 8, display: 'block' }}
      />
      <Space>
        <Button onClick={() => onSearchReset(clearFilters)} size='small' style={{ width: 100 }}>Reset</Button>
        <Button
          type='primary'
          onClick={() => onSearch(selectedKeys, confirm)}
          icon={<SearchOutlined/>}
          size='small'
          style={{ width: 120 }}
        >Search</Button>
      </Space>
    </div>
  );
}

SearchFilterDropdown.propTypes = {
  searchInput: PropTypes.shape({ current: PropTypes.any }),
  selectedKeys: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  setSelectedKeys: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onSearchReset: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
};
