import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Form, Modal, Progress, Spin, Upload, Tour, message, notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import config from 'react-global-configuration';

import history from '../../../utils/history';
import Mixpanel from '../../../analytics/mixpanel';
import GA from '../../../analytics/ga';
import { fetchIntegrations } from '../../../redux/actions/profileActions';
import { isPaidUser, parseQueries, isTaskReady as defaultIsTaskReady, arrayToText, toQueries, splitTaskByKey } from '../../../utils/utils';
import { sendExtractionTask, validateExtractionTask } from '../../../redux/actions/queriesActions';
import { getTemplateTask, getDefaultValue, setDefaultValue } from '../../../utils/defaultProps';
import Href from '../../../shared/Components/Href';
import ExtractorHeader from './ExtractorHeader';
import Pricing from '../../../shared/Components/Pricing';
import TaskConfirmationModal from '../../Tasks/TaskConfirmation/TaskConfirmationModal';
import ResultsTypeSelect from '../Common/ResultsTypeSelect';
import AdvancedParametersPanel from './AdvancedParametersPanel';
import OutputColumnsFormItem from '../Common/OutputColumnsFormItem';
import TagsFormItem from './TagsFormItem';
import StartTaskButton from './StartTaskButton';

const StyledSpin = styled(Spin)`
  margin-left: 10px;
`;

const QUERIES_AMOUNT_THRESHOLD = config.get('queriesSoftLimit');

export default function BaseService({ FormBody, FormBodyExtra, serviceName, title, subTitle, learnMoreUrl,
  startTaskButtonTitle, startTaskButtonIcon, unitName = 'item', taskExtraDefaultParams = {}, isTaskReady, tourSteps,
  formatTask, deformatTask, splitTask, taskUpdateAfterSubmit = { queries: '', input_file: null, enrich: false, tags: [] }, FormHeader, videoTutorialLink,
  tutorialLink, apiTag, fields, hasResult = true, beta = false, showParams = true, requiredIntegratoion,
}) {
  const { query: urlQuery } = queryString.parse(history.location.search);
  const templateTask = getTemplateTask();

  const loading = useSelector(state => state.queriesReducer.loading);
  const integrations = useSelector(state => state.profileReducer.integrations);

  const hasMounted = useRef(false);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const localTitle = t(`title.${title}`, title) + (beta ? ' (BETA)' : '');
  const localeSubTitle = subTitle ? t(`description.${serviceName}`, subTitle) : '';
  const localeStartTaskButtonTitle = startTaskButtonTitle ? t(`title.export${unitName}`, startTaskButtonTitle) : '';
  const rawSettings = localStorage.getItem(`${serviceName}Settings`);
  const [isTutorialVisible, setIsTutorialVisible] = useState(false);
  const [isSendingTasks, setIsSendingTasks] = useState(false);
  const [sendingTasksProgress, setSendingTasksProgress] = useState(0);
  const [isConfirm, setIsConfirm] = useState(false);
  const [task, setTask] = useState(() => {
    return templateTask ? handleDeformatTask(templateTask) : {
      service_name: serviceName,

      queries: urlQuery ? toQueries(urlQuery) : '',
      input_file: null,

      enrich: false,
      settings: rawSettings ? JSON.parse(rawSettings) : {
        output_extension: getDefaultValue('xlsx', 'outputExtension'),
      },
      tags: [],
      enrichments: [],
      ...taskExtraDefaultParams
    };
  });
  const [isTourVisible, setIsTourVisible] = useState(false);

  const FIRST_TIME_TOUR_STEPS = [{
    target: () => document.querySelector('.start-task'),
    title: t('tour.startTask', 'Once you select the parameters, you can start the task by clicking this button.'),
  },
  {
    target: () => document.querySelector('.tutorial'),
    title: t('tour.tutorial', 'If you need more help, here you can find tutorials on how to use this service.'),
  }];

  const paidUser = isPaidUser();
  const internalTourSteps = localStorage.getItem('firstTutorial') ? tourSteps : (tourSteps || []).concat(FIRST_TIME_TOUR_STEPS);
  const { settings = {}, input_file, enrich, enrichments = {} } = task;
  const formatedInputFile = input_file ? decodeURI(input_file) : input_file;
  const { output_extension: outputExtension = 'xlsx' } = settings;

  const startTaskDisabled = isTaskReady === undefined ? !defaultIsTaskReady(task) : !isTaskReady(task);

  useEffect(() => {
    document.title = localTitle + ' | Outscraper';

    if (!localStorage.getItem(`${serviceName}Tutorial`)) {
      setIsTourVisible(true);
    }

    if (requiredIntegratoion && isEmpty(integrations)) {
      dispatch(fetchIntegrations(true));
    }
  }, []);

  useEffect(() => {
    if (hasMounted.current) {
      if (requiredIntegratoion && (isEmpty(integrations) || !(requiredIntegratoion.toLowerCase() in integrations))) {
        notification.warning({
          message: `${requiredIntegratoion} integration required`,
          description: <>You will be redirected to the <a href='/integrations'>Integrations</a> page automatically, where you can link your account to {requiredIntegratoion}.</>,
          duration: 5
        });
        setTimeout(() => history.push('/integrations'), 5000);
      }
    } else {
      hasMounted.current = true;
    }

  }, [integrations]);

  function onFinishTour() {
    setIsTourVisible(false);
    localStorage.setItem(`${serviceName}Tutorial`, '1');
    localStorage.setItem('firstTutorial', '1');
  }

  function updateTask(params) {
    setTask(prevTask => ({ ...prevTask, ...params }));
  }

  function onSetSettings(value) {
    updateTask({ settings: value });
    localStorage.setItem(`${serviceName}Settings`, JSON.stringify(value));
  }

  function handleSplitTask(task, splitInto) {
    if (input_file) {
      return [{ ...task, split_into: splitInto }];
    } else if (splitTask) {
      return splitTask(task, splitInto);
    } else if (!isEmpty(task.queries)) {
      return splitTaskByKey(task, splitInto, 'queries');
    } else if (!isEmpty(task.locations) && !isEmpty(task.categories)) {
      return splitTaskByKey(task, splitInto, 'locations');
    }
  }

  function handleFormatTask(task) {
    if (formatTask) return formatTask(task);
    else {
      const { queries, cutoff, locations } = task;
      const result = { ...task, queries: parseQueries(queries) };
      if (cutoff) result.cutoff = cutoff.unix();
      if (locations && (typeof locations === 'string' || locations instanceof String)) result.locations = parseQueries(locations);
      return result;
    }
  }

  function handleDeformatTask(task) {
    if (deformatTask) return deformatTask(task);
    else {
      const { queries, cutoff } = task;
      const result = { ...task, queries: arrayToText(queries) };
      if (cutoff) result.cutoff = dayjs(cutoff*1000);
      return result;
    }
  }

  function onValidate() {
    GA.track('button', 'Validate task', serviceName);

    const formatedTask = handleFormatTask(task);
    const { queries, categories, locations } = formatedTask;

    if (queries && queries.length > QUERIES_AMOUNT_THRESHOLD) {
      formatedTask.queries_amount = queries.length;
      formatedTask.queries = queries.slice(0, QUERIES_AMOUNT_THRESHOLD);
    } else if (categories && locations) {
      const statesSelected = locations.filter((item) => (item.match(/>/g) || []).length === 1).length;

      if ((categories.length > 2500 && locations.length > 10) || (categories.length > 50 && statesSelected > 10)) {
        message.warning('Please, reduce the number of categories or locations per single task');
        return;
      }
    }

    setIsConfirm(true);
    dispatch(validateExtractionTask(formatedTask));
  }

  async function onSubmit(splitInto = 1, cost = 0, duration = 0) {
    let progress = 0;

    setIsConfirm(false);
    setIsSendingTasks(true);
    setSendingTasksProgress(progress);

    const formatedTask = handleFormatTask(task);
    if (duration) formatedTask.est = parseInt(duration);

    Mixpanel.track('Send task', { task: formatedTask, cost });
    GA.track('button', 'Send task', serviceName);

    const formatedTasks = splitInto > 1 ? handleSplitTask(formatedTask, splitInto) : [formatedTask];
    const stepProgress = Math.round(1 / formatedTasks.length * 100);
    let isFirstTask = false;

    for (const task of formatedTasks) {
      const isCurrentFirstTask = await dispatch(sendExtractionTask(task));
      if (isCurrentFirstTask) isFirstTask = true;
      progress = progress + stepProgress;
      setSendingTasksProgress(progress);
    }

    setSendingTasksProgress(100);
    setIsSendingTasks(false);
    updateTask(taskUpdateAfterSubmit);

    if (isFirstTask) setTimeout(() => history.push('/tasks'), 3000);
  }

  function onCancel() {
    setIsConfirm(false);
  }

  function onRemoveEnrichFile() {
    updateTask({ input_file: null, enrich: false });
  }

  function onOutputExtensionChange(output_extension) {
    updateTask({ settings: { ...settings, output_extension } });
    setDefaultValue(output_extension, 'outputExtension');
  }

  return <>
    <ExtractorHeader
      title={title}
      localTitle={localTitle}
      subTitle={<div className='tutorial'>
        {localeSubTitle && localeSubTitle}&nbsp;&nbsp;&nbsp;<Pricing service={serviceName} unitName={unitName} />
        {apiTag && <> | <Href href={'/api-docs#tag/' + apiTag}>{t('title.apiDocs', 'API Docs')}</Href></>}
        {learnMoreUrl && <> | <Href external href={learnMoreUrl}>{t('title.productPage', 'Product Page')}</Href></>}
        {tutorialLink && <> | <Href external href={tutorialLink}>📙 {t('title.tutorials', 'Tutorials')}</Href></>}
        {videoTutorialLink && <> | <a onClick={() => setIsTutorialVisible(true)}> 🎬 {t('title.videoTutorial', 'Video Tutorial')}</a></>}
      </div>}
      serviceName={serviceName}
    />
    {formatedInputFile && <Form layout='vertical'>
      <Form.Item label={t('title.uploadedFile', 'Uploaded file') + (enrich ? ' (enrichment mode)' : '')}>
        <Upload
          fileList={[{ uid: '1', name: formatedInputFile.split('/').pop(), status: 'done', url: formatedInputFile }]}
          onRemove={onRemoveEnrichFile}
        />
      </Form.Item>
    </Form>}
    {FormHeader && !enrich && <FormHeader task={task} updateTask={updateTask} />}

    <Form layout='vertical'>
      {!formatedInputFile && <FormBody task={task} updateTask={updateTask} isPaidUser={paidUser} />}
      {FormBodyExtra && <FormBodyExtra task={task} updateTask={updateTask} isPaidUser={paidUser} />}

      {showParams &&
        <AdvancedParametersPanel header={t('title.otherParameters', 'Other parameters (result format, task tags)')}>
          {hasResult && <>
            <Form.Item className='inlineFormItem' label={t('title.resultExtension', 'Result extension')}>
              <ResultsTypeSelect value={outputExtension} onChange={onOutputExtensionChange}/>
            </Form.Item>
          </>}
          <TagsFormItem task={task} onChange={setTask}/>
          {fields && fields.length > 2 && <OutputColumnsFormItem
            value={settings}
            onChange={onSetSettings}
            defaultFields={fields}
            enrichments={enrichments}
          />}
        </AdvancedParametersPanel>
      }

      <StartTaskButton
        onClick={onValidate}
        loading={loading}
        disabled={startTaskDisabled}
        title={localeStartTaskButtonTitle}
        icon={startTaskButtonIcon}
      />
    </Form>

    <TaskConfirmationModal
      open={isConfirm}
      onOk={onSubmit}
      onRevalidate={onValidate}
      onCancel={onCancel}
      enrichments={enrichments}
    />

    <Modal
      title={<>{t('title.sendingTasks', 'Sending Task(s)')}<StyledSpin size='small' /></>}
      open={isSendingTasks}
      closable={false}
      footer={null}
    >
      <Progress percent={sendingTasksProgress} />
    </Modal>

    <Modal
      centered
      destroyOnClose
      title={t('title.videoTutorial', 'Video Tutorial')}
      width={607}
      open={isTutorialVisible}
      onCancel={() => setIsTutorialVisible(false)}
      footer={null}
    >
      <iframe
        allowFullScreen
        width='560'
        height='315'
        src={videoTutorialLink}
        title={t('title.videoTutorial', 'Video Tutorial')}
        frameBorder='0'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
      />
    </Modal>

    {internalTourSteps && <Tour
      steps={internalTourSteps}
      open={isTourVisible}
      onClose={onFinishTour}
    />}
  </>;
}

BaseService.propTypes = {
  FormBody: PropTypes.func.isRequired,
  FormBodyExtra: PropTypes.func.isRequired,
  serviceName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  learnMoreUrl: PropTypes.string,
  unitName: PropTypes.string,
  startTaskButtonTitle: PropTypes.string,
  startTaskButtonIcon: PropTypes.object,
  taskExtraDefaultParams: PropTypes.object,
  isTaskReady: PropTypes.func,
  getFormatedTask: PropTypes.func,
  taskUpdateAfterSubmit: PropTypes.object,
  FormHeader: PropTypes.func,
  videoTutorialLink: PropTypes.string,
  tutorialLink: PropTypes.string,
  apiTag: PropTypes.string,
  formatTask: PropTypes.func,
  deformatTask: PropTypes.func,
  splitTask: PropTypes.func,
  fields: PropTypes.array,
  hasResult: PropTypes.bool,
  beta: PropTypes.bool,
  showParams: PropTypes.bool,
  requiredIntegratoion: PropTypes.string,
  tourSteps: PropTypes.array,
};
