import React, { useState, useEffect, useRef } from 'react';
import { isEmpty } from 'lodash';
import { Form, Button, List, Badge, message } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import config from 'react-global-configuration';

import { delay, parseQueries, toQueries } from '../../../../../utils/utils';
import apiClient from '../../../../../api/apiClient';
import CustomItemsSelect from '../../../../../Components/Services/Common/Selects/CustomItemsSelect';

const maxQueries = 5;
const hcaptchaSiteKey = config.get('hcaptchaSiteKey');

export default function EmailsValidator() {
  const captchaRef = useRef(null);
  const [token, setToken] = useState(undefined);
  const [queries, setQueries] = useState();
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);

  const showResults = !loading && !isEmpty(results);

  useEffect(() => {
    document.title = 'Emails Validator Lookup';
  }, []);

  useEffect(() => {
    if (queries) {
      const arrayQueries = parseQueries(queries);
      if (arrayQueries.length > 5) {
        setQueries(toQueries(parseQueries(queries).slice(0, maxQueries)));
      }
    }
  }, [queries]);

  function resetCaptcha() {
    setToken(undefined);
    if (captchaRef.current) {
      captchaRef.current.resetCaptcha();
    }
  }

  async function onVerify(token) {
    setToken(token);
    await search(token);
  }

  async function onSearch() {
    setToken(null);
  }

  async function search(token) {
    setLoading(true);
    try {
      const { data } = await apiClient.post('/email-validator-lookup', { token }, { params: { query: parseQueries(queries) }});

      if (isEmpty(data)) {
        message.error('Cannot get results');
        setResults({});
      } else {
        await delay(3000);
        setResults(data);
      }

    } catch (error) {
      message.error(error.message);
    }
    setLoading(false);
  }

  return <>
    <div style={{ width: 450, margin: 'auto', marginTop: 20 }}>
      <Form onFinish={onSearch}>
        <Form.Item>
          <CustomItemsSelect
            autoFocus
            rows={5}
            value={queries}
            onChange={setQueries}
            placeholder='john@company.com&#10;john@gmail.com'
          />
        </Form.Item>
        <Form.Item>
          <Button type='primary' htmlType='submit' loading={loading} disabled={!queries} icon={<CheckOutlined />}>Verify</Button>
        </Form.Item>
      </Form>
      {token === null &&
        <div style={{ marginTop: 15, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <HCaptcha
            ref={captchaRef}
            sitekey={hcaptchaSiteKey}
            onVerify={onVerify}
            onExpire={resetCaptcha}
          />
        </div>}
      {showResults && <List
        dataSource={results}
        renderItem={({ status, status_details, query }) => <List.Item>
          <List.Item.Meta title={query} description={status_details}/>
          <div><Badge status={status === 'RECEIVING' ? 'success' : 'error'} text={status} /></div>
        </List.Item>}
      />}
    </div>
  </>;
}
