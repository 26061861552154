import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Space } from 'antd';
import { Trans } from 'react-i18next';

import InfoTooltip from '../../../shared/Components/InfoTooltip';

export default function PlainQueriesSwitch({ checked, onChange, tooltip }) {
  function handleOnChange() {
    onChange(!checked);
  }

  return (
    <Space align='center' size={4} className='plain-queries'>
      <Switch size='small' checked={checked} onChange={handleOnChange}/> <Trans i18nKey='action.gm.plainQueriesTitle'>Plain queries</Trans>
      {tooltip && <InfoTooltip className='alignCenter' title={tooltip} />}
    </Space>
  );
}

PlainQueriesSwitch.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};
