import React from 'react';

import SimpleService from './Common/SimpleService';

export default function ExpediaReviewsScraper() {
  return (
    <SimpleService
      unitName='review'
      title='Expedia Reviews Scraper'
      startTaskButtonTitle='Export Reviews'
      subTitle='Returns reviews from a list of hotels'
      serviceName='expedia_reviews'
      queriesPlaceholder='https://www.expedia.com/Las-Vegas-Hotels-The-Orleans-Hotel-Casino.h41313.Hotel-Information&#10;41313'
      apiTag='Reviews-and-Comments/paths/~1expedia~1reviews/get'
      defaultParams={{ sort: 'NEWEST_TO_OLDEST_BY_LANGUAGE' }}
      recentCutoffSorting={'NEWEST_TO_OLDEST'}
      sortingOptions={[
        { value: 'NEWEST_TO_OLDEST_BY_LANGUAGE', text: 'Most relevant' },
        { value: 'NEWEST_TO_OLDEST', text: 'Most recent' },
        { value: 'HIGHEST_TO_LOWEST_RATED', text: 'Highest guest rating' },
        { value: 'LOWEST_TO_HIGHEST_RATED', text: 'Lowest guest rating' },
      ]}
    />
  );
}
