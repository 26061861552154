import React from 'react';
import PropTypes from 'prop-types';
import { Select, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import ReactCountryFlag from 'react-country-flag';
import styled from 'styled-components';
import { regions } from '../../../shared/data/regions';
import InfoTooltip from '../../../shared/Components/InfoTooltip';

const { Option } = Select;

const CountryOption = styled.span`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export default function CountrySelect({ value, onChange, tooltip, allowClear = true, size }) {
  const { t } = useTranslation();

  return (
    <Space align='center'>
      <Select
        allowClear={allowClear}
        showSearch
        size={size}
        style={{ width: 200 }}
        value={value}
        optionFilterProp='title'
        onChange={onChange}
        placeholder={t('action.selectCountry', 'Select country')}
      >
        {regions.map((r) => (
          <Option key={r.value} title={`${r.name}-${r.value}`} value={r.value}>
            <CountryOption>
              <ReactCountryFlag countryCode={r.value} svg />
              {r.name}
            </CountryOption>
          </Option>
        ))}
      </Select>
      {tooltip && <InfoTooltip title={tooltip} />}
    </Space>
  );
}

CountrySelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  tooltip: PropTypes.bool,
  allowClear: PropTypes.bool,
  size: PropTypes.string,
};
