import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import jwt_decode from 'jwt-decode';

import apiProfile from '../api/apiProfile';
import LoginSignupForm from './Common/LoginSignupForm';
import PasswordFormItem from './Common/PasswordFormItem';

export default function ResetPasswordConfirm({ location, match }) {
  const { token } = match.params;
  const payload = jwt_decode(token);
  const { email } = payload;

  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  async function onFinish(payload){
    setLoading(true);

    try {
      const { password } = payload;
      await apiProfile.changePassword({ password, token });
      notification.success({ message: t('title.created', 'Created') });

      window.location = '/login';
    } catch (error) {
      notification.error({ message: error.message });
    } finally {
      setLoading(false);
    }
  }

  return <LoginSignupForm
    title='Change Password'
    subTitle={`Enter a new password for ${email}`}
    skipLogin={false}
    loading={loading}
    location={location}
    onFinish={onFinish}
  >
    <PasswordFormItem />
  </LoginSignupForm>;
}

ResetPasswordConfirm.propTypes = {
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};
