import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Popover, Form, Select, Button, Row, Col, Tooltip, Space } from 'antd';
import { useTranslation, Trans } from 'react-i18next';

import { isAdvancedUser } from '../../../../utils/localDB';

const { Option, OptGroup } = Select;

const COMMON_FIELDS = ['subtypes', 'postal_code', 'business_status', 'verified'];
const ADVANCED_FIELDS = ['site', 'phone', 'rating'];
const FIELD_DESCRIPTIONS = {
  subtypes: 'All categories (types) of the place.',
  state: 'State of the place location.',
  city: 'City of the place location.',
  postal_code: 'Postal code of the place location.',
  business_status: 'Current status of the business.',
  verified: 'Indicates if the place was claimed by owner.',
  type: 'Type of the place under which it was found (can be any element from subtypes).',
  name: 'Name of the place on Google Maps.',
  site: 'Website of the place.',
  phone: 'Place phone number.',
  rating: 'Rating of the place.',
  category: 'The main type of the place (sometimes might be in a local language, e.g., galleria d\'arte).',
};
const FIELD_PLACEHOLDERS = {
  subtypes: 'restaurant, bar, etc.',
  state: 'New York, etc.',
  city: 'Palo Alto, New York, etc.',
  postal_code: '80121, 80001, etc.',
  type: 'restaurant, bar, etc.',
  name: 'KFC, Trump Tower, etc.',
  site: 'outscraper.com',
  rating: '4.0, 4.4, etc.',
  category: 'restaurant, bar, etc.',
};
const LIMITED_FIELD_VALUES = {
  business_status: ['operational', 'closed_temporarily', 'closed_permanently'],
  verified: ['true', 'false'],
};
const LIMITED_FIELD_OPERATORS = ['equals'];
const SIMPLE_OPERATORS = ['is not blank', 'is blank'];
const TEXT_OPERATORS = ['contains one of', 'contains none of', 'starts with', 'equals'];

export const CITY_FILTER_REGIONS = new Set(['US', 'GB', 'CA', 'AU', 'IN']);
export const CITY_FILTER_FIELDS = ['city', 'state'];
export const MAX_VALUES_LENGTH = 100;

const HIDDEN_VALUE_OPERATORS = new Set(['is blank', 'is not blank']);
const SIMPLE_OPERATORS_FIELDS = new Set(['phone', 'site']);

export default function AddEditFilter({ isAdd = true, defaultKey = COMMON_FIELDS[0], defaultOperator = TEXT_OPERATORS[0], defaultValue, index, onChange, children, onRemoveFilter, disabled = false, region }) {
  const [visible, setVisible] = useState(false);
  const [editingKey, setEditingKey] = useState(defaultKey);
  const [editingOperator, setEditingOperator] = useState(defaultOperator);
  const [editingValue, setEditingValue] = useState(defaultValue);

  const { t } = useTranslation();

  const isSaveDisabled = isEmpty(editingKey);
  const limitedSelectableValues = LIMITED_FIELD_VALUES[editingKey];

  const operators = limitedSelectableValues ? LIMITED_FIELD_OPERATORS : (SIMPLE_OPERATORS_FIELDS.has(editingKey) ? SIMPLE_OPERATORS : [...TEXT_OPERATORS, ...SIMPLE_OPERATORS]);
  const advanced_fields = (CITY_FILTER_REGIONS.has(region) || isAdvancedUser) ? [...ADVANCED_FIELDS, ...CITY_FILTER_FIELDS] : ADVANCED_FIELDS;
  const showValue = !HIDDEN_VALUE_OPERATORS.has(editingOperator);

  function onSetEditingValue(value) {
    setEditingValue(value.slice(0, MAX_VALUES_LENGTH));
  }

  function onVisibleChange() {
    if (visible) {
      if (!isSaveDisabled) {
        onSave();
      }
    } else {
      onSetEditingKey(defaultKey);
    }

    if (disabled) return;

    setVisible(!visible);
  }

  function onSave() {
    if (isEmpty(editingValue) && showValue) {
      if (onRemoveFilter) onRemoveFilter(index);
    } else {
      onChange({
        key: editingKey,
        operator: editingOperator,
        value: showValue ? editingValue : null,
      }, index);
    }
    onCancel();
  }

  function onCancel() {
    onSetEditingKey(defaultKey);
    setVisible(false);
  }

  function onSetEditingKey(newEditingKey) {
    setEditingKey(newEditingKey);

    if (SIMPLE_OPERATORS_FIELDS.has(newEditingKey)) {
      onSetEditingOperator(SIMPLE_OPERATORS[0]);
    } else if (limitedSelectableValues) {
      onSetEditingOperator(LIMITED_FIELD_OPERATORS[0]);
    } else {
      setEditingValue(defaultValue);
    }
  }

  function onSetEditingOperator(newOperator) {
    setEditingOperator(newOperator);
    setEditingValue(defaultValue);
  }

  const popoverTitle = () => (
    <h4 className='h4 mb-24'>
      {t(isAdd ? 'title.addFilter' : 'title.editFilter', isAdd ? 'Add filter' : 'Edit filter')}
    </h4>
  );


  return <>
    <Popover
      destroyTooltipOnHide
      placement='bottomLeft'
      trigger='click'
      title={popoverTitle}
      open={visible}
      onOpenChange={onVisibleChange}
      content={<>
        <Space direction={'vertical'}>
          <Form.Item className='inlineFormItem2' label={t('title.field', 'Field')}>
            <Select
              showSearch
              placeholder={t('title.field', 'Field')}
              style={{ width: 230 }}
              value={editingKey}
              onChange={onSetEditingKey}
              optionLabelProp='value'
            >
              <OptGroup label='Common'>
                {COMMON_FIELDS.map(r => <Option key={r} value={r}>
                  <Tooltip mouseEnterDelay={0.3} title={FIELD_DESCRIPTIONS[r]}><div>{r}</div></Tooltip>
                </Option>)}
              </OptGroup>
              <OptGroup label='Advanced'>
                {advanced_fields.map(r => <Option key={r} value={r}>
                  <Tooltip mouseEnterDelay={0.3} title={FIELD_DESCRIPTIONS[r]}><div>{r}</div></Tooltip>
                </Option>)}
              </OptGroup>
              {isAdvancedUser && <>
                <OptGroup label='Very Advanced'>
                  <Option key='category' value='category'>
                    <Tooltip title={FIELD_DESCRIPTIONS.category}>category</Tooltip>
                  </Option>
                </OptGroup>
              </>}
            </Select>
          </Form.Item>

          <Form.Item className='inlineFormItem2' label={t('title.operator', 'Operator')}>
            <Select
              placeholder={t('title.operator', 'Operator')}
              style={{ width: 150 }}
              value={editingOperator}
              onChange={onSetEditingOperator}
            >
              {operators.map(r => <Option key={r} value={r}>{r}</Option>)}
            </Select>
          </Form.Item>

          {showValue && (
            <Form.Item className='inlineFormItem2' label={t('title.values', 'Values (one of)')}>
              <Select
                allowClear
                mode={limitedSelectableValues ? 'multiple' : 'tags'}
                notFoundContent={null}
                disabled={isSaveDisabled}
                tokenSeparators={['|', ',']}
                value={editingValue}
                style={{ width: 260 }}
                placeholder={limitedSelectableValues ? 'select' : (FIELD_PLACEHOLDERS[editingKey] || 'enter value')}
                onChange={onSetEditingValue}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !isSaveDisabled && !e.target.value) {
                    e.preventDefault();
                    onSave();
                  }
                }}
              >
                {limitedSelectableValues && limitedSelectableValues.map(r => <Option key={r} value={r}>{r}</Option>)}
              </Select>
            </Form.Item>
          )}
        </Space>
        <Row>
          <Col span={24} style={{ textAlign: 'right', paddingRight: 10 }}>
            <Button onClick={onCancel}><Trans i18nKey='action.cancel'>Cancel</Trans></Button>&nbsp;&nbsp;
            <Button type='primary' onClick={onSave} disabled={isSaveDisabled}><Trans i18nKey='title.save'>Save</Trans></Button>
          </Col>
        </Row>
      </>}>
      {children}
    </Popover>
  </>;
}

AddEditFilter.propTypes = {
  isAdd: PropTypes.bool,
  defaultKey: PropTypes.string,
  defaultOperator: PropTypes.string,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]),
  index: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  onRemoveFilter: PropTypes.func,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  region: PropTypes.string,
};
