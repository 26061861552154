import React from 'react';

import SimpleService from '../Common/SimpleService';
import { services, defaultPhoneEnrichments } from '../../../shared/data/services';

export default function WhatsappChecker() {
  return (
    <SimpleService
      unitName={services.whatsapp_checker.unit}
      title={services.whatsapp_checker.name}
      subTitle={services.whatsapp_checker.description}
      serviceName='whatsapp_checker'
      apiTag='Phone-Related/paths/~1whatasp-checker/get'
      queriesLabel='Phone numbers (international)'
      queriesPlaceholder='+1 281 236 8208&#10;442812368208'
      limitType={null}
      enrichmentFields={services.whatsapp_checker.input_fields}
      supportedEnrichmentServices={[]}
      defaultEnrichments={defaultPhoneEnrichments}
    />
  );
}
