import React from 'react';
import PropTypes from 'prop-types';
import { Form, InputNumber, Checkbox, DatePicker, Space } from 'antd';
import { useTranslation, Trans } from 'react-i18next';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';

import { services } from '../../../shared/data/services';
import BaseService from './BaseService';
import CustomQueries from './Selects/CustomQueries';
import EnrichmentsItem from '../Common/EnrichmentsItem';
import ReviewsSortSelect from './Selects/ReviewsSortSelect';
import InfoTooltip from '../../../shared/Components/InfoTooltip';
import PremiumFeatureTooltip from '../../../shared/Components/PremiumFeatureTooltip';

const FORM_STYLE = { maxWidth: 800 };

export default function SimpleService({ title, subTitle, serviceName, queriesLabel = 'Queries', queriesPlaceholder = '',
  unitName = 'item', limitType = 'per_query', defaultLimit = 100, maxLimit, sortingOptions, learnMoreUrl, videoTutorialLink, tutorialLink, apiTag,
  startTaskButtonTitle, fields, enrichmentFields, beta = false, defaultParams = {}, supportedEnrichmentServices, exludedEnrichmentServices,
  ExtraItems, QueriesFormItems, allowFileUpload = true, isTaskReady, showParams = true, requiredIntegratoion, tourSteps, recentCutoffSorting,
  serviceOntologies, defaultEnrichments = [],
}) {
  const { t } = useTranslation();
  const localeQueriesLabel = queriesLabel ? t(`title.${queriesLabel}`, queriesLabel) : '';
  const localEnrichmentsKey = `${serviceName}_enrichments`;
  const isTotalLimitType = limitType === 'total';

  function getServiceFields() {
    const serviceData = services[serviceName];
    if (isEmpty(serviceData) || isEmpty(serviceData.output_fields)) return null;
    return ['query'].concat(serviceData.output_fields);
  }

  return <>
    <BaseService
      title={title}
      subTitle={subTitle}
      serviceName={serviceName}
      unitName={unitName}
      beta={beta}
      fields={fields ? fields : getServiceFields()}
      startTaskButtonTitle={startTaskButtonTitle}
      learnMoreUrl={learnMoreUrl}
      videoTutorialLink={videoTutorialLink}
      tutorialLink={tutorialLink}
      apiTag={apiTag}
      tourSteps={tourSteps}
      isTaskReady={isTaskReady}
      requiredIntegratoion={requiredIntegratoion}
      taskExtraDefaultParams={{
        limit: isTotalLimitType ? defaultLimit : 0,
        limit_per_query: limitType ? defaultLimit : 1,
        enrichments: localStorage.getItem(localEnrichmentsKey) ? JSON.parse(localStorage.getItem(localEnrichmentsKey)) : defaultEnrichments,
        ...defaultParams
      }}
      showParams={showParams}
      FormBody={({ updateTask, task }) => {
        const { queries, limit, limit_per_query, input_file: inputFile, settings } = task;

        function onFileUploaded(input_file, enrich, output_extension) {
          updateTask({ input_file, enrich, queries: '', settings: { ...settings, output_extension } });
        }

        return <>
          {showParams && (
            QueriesFormItems ? <QueriesFormItems task={task} updateTask={updateTask} /> : <>
              <Form.Item required label={localeQueriesLabel} className='queries-input' style={FORM_STYLE}>
                <CustomQueries
                  value={queries}
                  onChange={(v) => updateTask({ queries: v })}
                  inputFile={inputFile}
                  onFileUploaded={allowFileUpload ? onFileUploaded : null}
                  placeholder={queriesPlaceholder}
                  enrichmentFields={enrichmentFields}
                />
              </Form.Item>
            </>
          )}

          {limitType && <>
            <Form.Item label={isTotalLimitType ? t('title.totalLimit', 'Total results limit') : t('title.perQueryLimit', 'Limit per one query')} className='formItem limit'>
              <Space align='center'>
                {isTotalLimitType ? (
                  <InputNumber
                    min={1}
                    step={100}
                    value={limit}
                    max={maxLimit}
                    onChange={(v) => updateTask({ limit: parseInt(v) || 1 })}
                  />
                ) : (
                  <InputNumber
                    min={1}
                    step={100}
                    value={limit_per_query}
                    max={maxLimit}
                    onChange={(v) => updateTask({ limit_per_query: parseInt(v) || 1 })}
                  />
                )}
                <InfoTooltip title={isTotalLimitType ? t('description.totalLimit', 'Parameter specifies the total limit of results') : t('description.perQueryLimit', 'Parameter specifies the limit of results per one query')} />
              </Space>
            </Form.Item><br/>
          </>}
        </>;
      }}
      FormBodyExtra={({ updateTask, task, isPaidUser }) => {
        const { sort, cutoff } = task;
        const useCutoffDate = !!cutoff;

        function onEnrichmentChange(enrichments) {
          localStorage.setItem(localEnrichmentsKey, JSON.stringify(enrichments));
          updateTask({ enrichments });
        }

        function onSetCutoffDate(value) {
          updateTask({ cutoff: value });
        }

        function onSetUseCutoffDate() {
          updateTask({ cutoff: cutoff ? null : dayjs().startOf('month') });
        }

        function disabledCutoffDate(current) {
          let result = current > dayjs();
          return result;
        }

        function onSetSorting(value) {
          updateTask({ sort: value });
        }

        return <>
          {ExtraItems && <ExtraItems task={task} updateTask={updateTask} isPaidUser={isPaidUser} />}

          {supportedEnrichmentServices &&
          <>
            <EnrichmentsItem
              task={task}
              updateTask={updateTask}
              onChange={onEnrichmentChange}
              supportedEnrichmentServices={supportedEnrichmentServices}
              exludedEnrichmentServices={exludedEnrichmentServices}
              serviceOntologies={serviceOntologies}
              title={title}
              learnMoreUrl={learnMoreUrl}
            />
          </>
          }

          {sortingOptions && <>
            <Form.Item label={t('title.sorting', 'Sorting')}>
              <Space align='center'>
                <ReviewsSortSelect onChange={onSetSorting} value={sort} options={sortingOptions}/>
                <InfoTooltip title={t('description.sorting', 'Parameter specifies one of the sorting types')}/>
              </Space>
            </Form.Item>

            {recentCutoffSorting !== undefined && recentCutoffSorting === sort && <Form.Item>
              <PremiumFeatureTooltip isPaidUser={isPaidUser}>
                <Checkbox disabled={!isPaidUser} checked={useCutoffDate} onChange={() => onSetUseCutoffDate()}><Trans i18nKey='action.gmr.newestFrom'>From (oldest)</Trans></Checkbox>
              </PremiumFeatureTooltip>
              {useCutoffDate && <DatePicker
                showTime
                format={'YYYY/MM/DD HH:mm:ss'}
                value={cutoff}
                onChange={onSetCutoffDate}
                disabledDate={disabledCutoffDate}
              />}
            </Form.Item>}
          </>}
        </>;
      }}
    />
  </>;
}

SimpleService.propTypes = {
  title: PropTypes.string,
  serviceName: PropTypes.string,
  queriesLabel: PropTypes.string,
  queriesPlaceholder: PropTypes.string,
  limitType: PropTypes.string,
  sortingOptions: PropTypes.array,
  recentCutoffSorting: PropTypes.string,
  defaultLimit: PropTypes.number,
  maxLimit: PropTypes.number,
  defaultParams: PropTypes.object,
  unitName: PropTypes.string,
  subTitle: PropTypes.string,
  learnMoreUrl: PropTypes.string,
  videoTutorialLink: PropTypes.string,
  apiTag: PropTypes.string,
  tutorialLink: PropTypes.string,
  startTaskButtonTitle: PropTypes.string,
  fields: PropTypes.array,
  enrichmentFields: PropTypes.array,
  supportedEnrichmentServices: PropTypes.array,
  exludedEnrichmentServices: PropTypes.array,
  serviceOntologies: PropTypes.array,
  beta: PropTypes.bool,
  ExtraItems: PropTypes.func,
  QueriesFormItems: PropTypes.func,
  allowFileUpload: PropTypes.bool,
  isTaskReady: PropTypes.func,
  showParams: PropTypes.bool,
  requiredIntegratoion: PropTypes.string,
  tourSteps: PropTypes.array,
  defaultEnrichments: PropTypes.array,
};
