import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';

const { Option } = Select;

function ResultsTypeSelect({ value, onChange }) {
  return (
    <Select
      style={{ width: 100 }}
      value={value}
      onChange={onChange}
    >
      <Option value='xlsx'>XLSX</Option>
      <Option value='csv'>CSV</Option>
      <Option value='json'>JSON</Option>
      <Option value='parquet'>Parquet</Option>
    </Select>
  );
}

ResultsTypeSelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default React.memo(ResultsTypeSelect, (prevProps, nextProps) => {
  return prevProps.value === nextProps.value;
});
