import React from 'react';
import PropTypes from 'prop-types';
import { Statistic } from 'antd';
import { isEmpty } from 'lodash';
import { Trans, useTranslation } from 'react-i18next';

import config from 'react-global-configuration';
import AddCredits from './AddCredits/AddCredits';

export default function Balance({ loading, profile, onMakePayment, region, crypto, emailVerified, onPaymentMade }) {
  const { balance, coupon = {}, account_status, upcoming_invoice = {}, risk_level, personal } = profile;
  const { duration_in_months, duration } = coupon;
  const couponDuration = duration_in_months ? `for ${duration_in_months} months` : duration;
  const { name: couponName } = coupon;
  const { t } = useTranslation();

  return <>
    <Statistic
      prefix='$'
      value={balance}
      precision={2}
      title={`${t('title.remainingCredits', 'Your remaining credits')}${isEmpty(upcoming_invoice) ? '' : (' (' + t('description.remainingCredits', 'after applying upcoming invoice') + ')')}`}
      loading={loading}
    />
    <br/>
    <br/>

    <h3>{t('title.addCredits', 'Top Up')}</h3>
    <AddCredits loading={loading} onMakePayment={onMakePayment} region={region} crypto={crypto} riskLevel={risk_level} emailVerified={emailVerified} personal={personal} onPaymentMade={onPaymentMade} />
    <br/>
    <br/>
    <br/>
    {account_status === 'no_default_source' && <>
      <p className='help-p2'>
        * <Trans i18nKey='description.usingCredits'>Use your deposit for any services at listed <a href={`${config.get('pricingUrl')}`} target='_blank' rel='noopener noreferrer'>prices</a>, with no expiration</Trans>.
      </p>
    </>}
    <br/>

    {couponName && <>
      <Statistic title={t('title.specialOffer', 'Special Offer')} value={couponName} suffix={couponDuration}/><br/><br/>
    </>}
  </>;
}

Balance.propTypes = {
  loading: PropTypes.bool,
  region: PropTypes.string,
  crypto: PropTypes.bool,
  profile: PropTypes.object.isRequired,
  isTenant: PropTypes.bool,
  onMakePayment: PropTypes.func.isRequired,
  emailVerified: PropTypes.bool,
  onPaymentMade: PropTypes.func,
};
