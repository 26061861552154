import React, { useState, useEffect } from 'react';
import { Button, Modal, Space, Checkbox } from 'antd';
import { Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import PremiumFeatureTooltip from '../../../../../shared/Components/PremiumFeatureTooltip';
import InfoTooltip from '../../../../../shared/Components/InfoTooltip';
import { isPaidUser } from '../../../../../utils/utils';

export const FiltersModal = ({ task, onChange, isOpen, onClose }) => {
  const [localIgnoreWithoutEmails, setLocalIgnoreWithoutEmails] = useState(task.ignoreWithoutEmails);
  const [localDropEmailDuplicates, setLocalDropEmailDuplicates] = useState(task.dropEmailDuplicates);

  const paidUser = isPaidUser();

  useEffect(() => {
    setLocalIgnoreWithoutEmails(task.ignoreWithoutEmails);
    setLocalDropEmailDuplicates(task.dropEmailDuplicates);
  }, [task, isOpen]);

  const toggleIgnoreWithoutEmails = () => {
    setLocalIgnoreWithoutEmails(prev => !prev);
  };

  const toggleDropEmailDuplicates = () => {
    setLocalDropEmailDuplicates(prev => !prev);
  };

  const handleApplyFilter  = () => {
    onChange({
      ignoreWithoutEmails: localIgnoreWithoutEmails,
      dropEmailDuplicates: localDropEmailDuplicates
    });
    onClose();
  };

  const modalFooter = (
    <>
      <Button key='cancel' onClick={onClose}>
        <Trans i18nKey='action.cancel'>Cancel</Trans>
      </Button>
      <Button disabled={!paidUser} type='primary' key='save' onClick={handleApplyFilter}>
        <Trans i18nKey='title.save'>Save</Trans>
      </Button>
    </>
  );

  return (
    <Modal centered width={300} open={isOpen} closable={false} footer={modalFooter}>
      <Space direction='vertical' size={16}>
        <Space>
          <PremiumFeatureTooltip isPaidUser={paidUser}>
            <Space className='main-text' align='center'>
              <Checkbox
                disabled={!paidUser}
                checked={localIgnoreWithoutEmails}
                onChange={toggleIgnoreWithoutEmails}
              >
                <Trans i18nKey='action.ignoreWithoutEmails'>Ignore without emails</Trans>
              </Checkbox>
            </Space>
          </PremiumFeatureTooltip>
          <InfoTooltip
            marginLeft={0}
            title={
              <Trans i18nKey='description.ignoreWithoutEmails'>
                  Drop the places without at least one email found from your results (only if Emails & Contacts Scraper
                  selected as enrichment).<br /><br />If the amount of ignored rows is less than 5,000% of what was
                  actually extracted, you won&apos;t be billed for ignored records.
              </Trans>
            }
          />
        </Space>
        <Space>
          <PremiumFeatureTooltip isPaidUser={paidUser}>
            <Space className='main-text' align='center'>
              <Checkbox
                disabled={!paidUser}
                checked={localDropEmailDuplicates}
                onChange={toggleDropEmailDuplicates}
              >
                <Trans i18nKey='action.dropEmailDuplicates'>Delete email duplicates</Trans>
              </Checkbox>
            </Space>
          </PremiumFeatureTooltip>
          <InfoTooltip
            marginLeft={0}
            title={
              <Trans i18nKey='description.dropEmailDuplicates'>
                  Drop the places with the same email (e.g., when the same company has many locations).<br /><br />
                  If the amount of ignored rows is less than 5,000% of what was actually extracted, you won&apos;
                  t be billed for ignored records.
              </Trans>
            }
          />
        </Space>
      </Space>
    </Modal>
  );
};

FiltersModal.propTypes = {
  task: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};