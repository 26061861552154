import React from 'react';
import { Form, Select } from 'antd';
import { useTranslation } from 'react-i18next';

import SimpleService from './Common/SimpleService';

const { Option } = Select;

export default function TrustpilotReviewsScraper() {
  const { t } = useTranslation();

  return (
    <SimpleService
      unitName='review'
      title='Trustpilot Reviews Scraper'
      subTitle='Returns reviews from a list of businesses'
      startTaskButtonTitle='Export Reviews'
      serviceName='trustpilot_reviews_service'
      queriesLabel='URLs to company profiles, company IDs'
      queriesPlaceholder='https://www.trustpilot.com/review/outscraper.com&#10;outscraper.com'
      learnMoreUrl='https://outscraper.com/trustpilot-reviews-scraper'
      apiTag='Trustpilot/paths/~1trustpilot~1reviews/get'
      enrichmentFields={['site', 'domain', 'website']}
      defaultParams={{ sort: '', stars: [], languages: 'all' }}
      recentCutoffSorting={'recency'}
      sortingOptions={[
        { value: '', text: 'Most relevant (default)' },
        { value: 'recency', text: 'Most recent' },
      ]}
      ExtraItems={({ updateTask, task }) => {
        const { languages = '', stars = [] } = task;

        return <>
          <Form.Item label={t('title.language', 'Language')}>
            <Select
              value={languages}
              style={{ width: 300 }}
              onChange={(v) => updateTask({ languages: v })}
            >
              <Option key='all' value='all'>All</Option>
              <Option key='default' value=''>Default</Option>
            </Select>
          </Form.Item>
          <Form.Item label={t('title.filtering', 'Filtering')}>
            <Select
              allowClear
              mode='multiple'
              value={stars}
              style={{ width: 300 }}
              onChange={(v) => updateTask({ stars: v })}
            >
              <Option key='5' value='5'>5</Option>
              <Option key='4' value='4'>4</Option>
              <Option key='3' value='3'>3</Option>
              <Option key='2' value='2'>2</Option>
              <Option key='1' value='1'>1</Option>
            </Select>
          </Form.Item>
        </>;
      }}
    />
  );
}
