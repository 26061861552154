import mixpanel from 'mixpanel-browser';
import config from 'react-global-configuration';

// eslint-disable-next-line no-undef
let prodEnv = process.env.NODE_ENV === 'production';

const actions = {
  init: () => {
    try {
      if (prodEnv) {
        mixpanel.init(config.get('mixpanelToken'));
      }
    } catch (error) {
      console.error('mixpanel error:', error);
    }
  },
  identify: (id) => {
    try {
      if (prodEnv) {
        mixpanel.identify(id);
      }
    } catch (error) {
      console.error('mixpanel error:', error);
    }
  },
  alias: (id) => {
    try {
      if (prodEnv) {
        mixpanel.alias(id);
      }
    } catch (error) {
      console.error('mixpanel error:', error);
    }
  },
  track: (title, props) => {
    try {
      if (prodEnv) {
        mixpanel.track(title, props);
      }
    } catch (error) {
      console.error('mixpanel error:', error);
    }
  }
};

export default actions;
