import apiClient from './apiClient';

export default {
  createAPIToken: () => apiClient.put('/profile/api-token'),
  revokeAPIToken: () => apiClient.delete('/profile/api-token'),
  updateGodMode: (email) => apiClient.put('/profile/god-mode', { email } ),
  removeAndBlockUserByIP: (email) => apiClient.put('/profile/remove-and-block', { email } ),
  applyPromotionCode: (code) => apiClient.put('/profile/apply-promotion-code', { code }),
  fetchIntegrations: () => apiClient.get('/profile/integrations'),
  removeIntegration: (integration) => apiClient.delete(`/profile/integrations/${integration}`),
  connectHubSpot: (code) => apiClient.put('/profile/connect-hubspot', { code }),
  connectZoho: (code, region) => apiClient.put('/profile/connect-zoho', { code, region }),
  connectPipedrive: (code) => apiClient.put('/profile/connect-pipedrive', { code }),
  featureRequest: (body, bug) => apiClient.post('/profile/feature-request', { body, bug }),
  getAuthToken: (payload) => apiClient.post('/auth/token', payload),
  refreshAuthToken: (token) => apiClient.put('/auth/token', { access_token: token }),
  requestPasswordChange: (email) => apiClient.post('/profile/forget-password', { email }),
  changePassword: (payload) => apiClient.post('/profile/change-password', payload),
  createProfile: (payload) => apiClient.post('/profile', payload),
  updateProfile: (payload) => apiClient.put('/profile', payload),
  verifyEmail: (token) => apiClient.post(`/profile/verify-email/${token}`),
  updateKYCFinished: (payload) => apiClient.put('/profile/on-kyc-finished', payload),
  requestEmailConfirmationCode: (email) => apiClient.put('/profile/change-email/request-code', { email }),
  changeEmail: (email) => apiClient.put('/profile/change-email', { email }),
  inviteTenant: (email) => apiClient.post('/profile/tenants', { email }),
  removeTenant: (tenantUserId) => apiClient.delete(`/profile/tenants/${tenantUserId}`),
  reuqestEmailVerification: () => apiClient.put('/profile/request-email-verification'),
};
