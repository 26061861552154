import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Steps, Form, Select, Button, message } from 'antd';
import { isEmpty } from 'lodash';
import { useTranslation, Trans } from 'react-i18next';
import { LeftOutlined } from '@ant-design/icons';

import GA from '../analytics/ga';
import titles from '../shared/data/titles.json';
import industries from '../shared/data/industries.json';
import apiProfile from '../api/apiProfile';

const StyledButton = styled(Button)`
  width: 100%;
`;

const StyledBackButton = styled(Button)`
  margin-left: 50px;
`;

const StyledDiv = styled.div`
  margin: auto;
  max-width: 350px;
`;

const { Step } = Steps;
const { Option } = Select;

export default function KYC({ auth }) {
  const { profile } = auth;
  const { emailVerified, email } = profile;

  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [data, setData] = useState({});
  const { t } = useTranslation();

  const nextButtonRef = useRef(null);

  if (!localStorage.getItem('kyc')) {
    goToRedirect();
  }

  const preparingYourAccountLocalLabel = t('title.preparingYourAccount', 'Preparing your account');
  const { job_title, industry } = data;
  const STEPS = emailVerified ? 2 : 3;

  useEffect(() => {
    if (nextButtonRef.current) {
      nextButtonRef.current.focus();
    }
  }, [step]);

  async function onNext() {
    GA.track('button', 'KYC next step click', `KYC step ${step + 1}`);

    if (step + 1 >= STEPS) {
      setLoading(true);
      message.loading({ content: preparingYourAccountLocalLabel, key: 'main' });

      if (localStorage.getItem('kyc')) {
        localStorage.removeItem('kyc');

        try {
          const customIndustries = [];
          const enteredIndustries = data['industry'];

          for (let i = enteredIndustries.length - 1; i >= 0; i--) {
            const industry = enteredIndustries[i];
            if (!industries.includes(industry)) {
              customIndustries.push(industry);
              enteredIndustries.splice(i, 1);
            }
          }

          data['industry'] = enteredIndustries.join(';');
          if (customIndustries.length) data['note'] = customIndustries.join('\n');

          await apiProfile.updateKYCFinished(data);
        } catch (error) {
          console.error(error);
        }
      }

      setLoading(false);
      message.success({ content: t('title.ready', 'Ready'), key: 'main' });

      setData({});
      goToRedirect();
    }

    setStep(step + 1);
  }

  function goToRedirect() {
    const redirect = localStorage.getItem('redirect');
    localStorage.removeItem('redirect');
    window.location = redirect || '/';
  }

  function onPrevious() {
    setStep(step - 1);
  }

  return <>
    <br/>
    <Steps progressDot current={step}>
      {new Array(STEPS + 1).fill(null).map((_, i) => <Step key={i} />)}
    </Steps>
    <br/>
    <br/>

    <StyledBackButton
      type='link'
      icon={<LeftOutlined />}
      onClick={onPrevious}
      disabled={step <= 0 || loading}
    ><Trans i18nKey='title.back'>Back</Trans></StyledBackButton>

    <StyledDiv>
      <Form name='kyc' layout='vertical'>
        {step === 0 &&
          <Form.Item colon={false} label={t('title.whatIndustry', 'What industry(ies) are you in?')} className='inlineFormItem'>
            <Select
              mode='tags'
              allowClear
              autoFocus
              showSearch
              maxTagCount={3}
              showArrow={false}
              style={{ width: 350 }}
              value={industry}
              placeholder={t('title.searchIndustries', 'Search industries')}
              optionFilterProp='children'
              onChange={(v) => setData({...data, industry: v})}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && industry) {
                  e.preventDefault();
                  onNext();
                }
              }}
            >
              {industries.map(t => <Option key={t} value={t}>{t}</Option>)}
            </Select>
          </Form.Item>
        }

        {step === 1 &&
          <Form.Item colon={false} label={t('title.whatJobRole', 'What is your job role?')} className='inlineFormItem'>
            <Select
              allowClear
              autoFocus
              showSearch
              notFoundContent={job_title ? t('title.jobRoleNotFound', 'We could not find your job role. Try again or select "Other"') : t('title.typeToSearch', 'Type to search')}
              showArrow={false}
              style={{ width: 350 }}
              value={job_title}
              placeholder={t('title.searchJobRoles', 'Search job roles')}
              optionFilterProp='children'
              onChange={(v) => setData({...data, job_title: v})}
              onSearch={(v) => setData({...data, job_title: v})}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && job_title) {
                  e.preventDefault();
                  onNext();
                }
              }}
            >
              {job_title && titles.map(t => <Option key={t} value={t}>{t}</Option>)}
            </Select>
          </Form.Item>
        }

        {(step === 2 && !emailVerified) && <>
          <h2><Trans i18nKey='title.checkInbox'>Check Your Inbox</Trans></h2>

          <p>
            <span className='nobreak'><Trans i18nKey='description.checkEmail'>We&apos;ve sent you an email to</Trans> <strong>{email}</strong><Trans i18nKey='dot'>.</Trans></span>
            <br/>
            <br/>
            <Trans i18nKey='description.checkEmailClickLink'>Click the link in the email to confirm your address and activate your account.</Trans>
            <br/>
            <br/>
          </p>
        </>}

        <Form.Item>
          <StyledButton
            ref={nextButtonRef}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                onNext();
              }
            }}
            type='primary'
            onClick={onNext}
            loading={loading}
            disabled={(step === 0 && isEmpty(industry)) || (step === 1 && isEmpty(job_title)) || loading}
          ><Trans i18nKey='title.next'>Next</Trans></StyledButton>
        </Form.Item>
      </Form>
    </StyledDiv>
  </>;
}

KYC.propTypes = {
  location: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};
