import React from 'react';

import SimpleService from './Common/SimpleService';

export default function GlassdoorSearchScraper() {
  return (
    <SimpleService
      unitName='job'
      title='Glassdoor Job Scraper'
      subTitle='Returns search results'
      queriesLabel='Search URLs'
      serviceName='glassdoor_search'
      learnMoreUrl='https://outscraper.com/glassdoor-scraper/'
      queriesPlaceholder='https://www.glassdoor.com/Job/los-angeles-ca-us-python-jobs-SRCH_IL.0,17_IC1146821_KO18,24.htm'
    />
  );
}
