import React from 'react';

import SimpleService from './Common/SimpleService';

export default function AirbnbReviews() {
  return (
    <SimpleService
      unitName='review'
      title='Airbnb Reviews Scraper'
      startTaskButtonTitle='Export Reviews'
      subTitle='Returns reviews from accommodations'
      serviceName='airbnb_reviews'
      learnMoreUrl='https://outscraper.com/airbnb-reviews-scraper/'
      queriesPlaceholder='https://www.airbnb.com/rooms/927539322986647456&#10;1125445123101274851'
      apiTag='Reviews-and-Comments/paths/~1airbnb~1reviews/get'
      defaultParams={{ sort: 'MOST_RECENT' }}
      recentCutoffSorting={'MOST_RECENT'}
      sortingOptions={[
        { value: 'MOST_RECENT', text: 'Most recent' },
        { value: 'RATING_DESC', text: 'Highest rated' },
        { value: 'RATING_ASC', text: 'Lowest rated' },
      ]}
    />
  );
}
