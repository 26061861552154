import React from 'react';

import SimpleService from './Common/SimpleService';

export default function GlassdoorReviewsScraper() {
  return (
    <SimpleService
      unitName='review'
      title='Glassdoor Reviews Scraper'
      startTaskButtonTitle='Export Reviews'
      subTitle='Returns reviews from a list of companies'
      serviceName='glassdoor_reviews_service'
      queriesPlaceholder='https://www.glassdoor.com/Reviews/Amazon-Reviews-E6036.htm&#10;https://www.glassdoor.com/Reviews/USA-Jobs-Reviews-E221792.htm'
      apiTag='Reviews-and-Comments/paths/~1glassdoor~1reviews/get'
      defaultParams={{ sort: 'DATE' }}
      recentCutoffSorting={'DATE'}
      sortingOptions={[
        { value: 'DATE', text: 'Most Recent' },
        { value: 'RELEVANCE', text: 'Popular' },
      ]}
    />
  );
}
