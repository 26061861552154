import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Input, message, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import config from 'react-global-configuration';

import Mixpanel from '../../analytics/mixpanel';
const homeUrl = config.get('homeUrl');

const StyledButton = styled(Button)`
  margin-left: 8px;
`;

export default function ReferralProgram({ userId, disabled, referred }) {
  const referrer = userId ? btoa(userId) : '';
  const { t } = useTranslation();

  function onClickFromInput() {
    onCopy();
  }

  function onCopy() {
    Mixpanel.track('Copy referral link');

    var referrerLink = document.querySelector('.referrer-link');
    referrerLink.select();
    document.execCommand('copy');

    message.success(t('description.copied', 'Copied to your clipboard'));
  }

  return <span className='nobreak'>
    <Input
      readOnly
      className='referrer-link copieble'
      value={`https://${homeUrl}/refer?referrer=${referrer}`}
      disabled={disabled}
      onClick={onClickFromInput}
    />
    <StyledButton
      title={t('title.copyToClipboard', 'Copy to Clipboard')}
      icon={<FontAwesomeIcon icon={faCopy}/>}
      onClick={onCopy}
    />

    {referred && <><br/>{t('title.referredUsers', 'Referred users')}: {referred.toLocaleString()}</>}
  </span>;
}

ReferralProgram.propTypes = {
  userId: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  referred: PropTypes.number,
};
