import React from 'react';
import PropTypes from 'prop-types';
import { Button, Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';
import { PauseCircleOutlined, PlayCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  margin: 0 5px;
`;
StyledButton.defaultProps = { type: 'default', size: 'small', shape: 'round' };

export default function ScheduledTaskActions({ task = {}, onRemove = null, onPause = null, onResume = null }) {
  const { id, next_run } = task;
  const active = !!next_run;
  const { t } = useTranslation();

  function onChangeState(newStateActive) {
    if (newStateActive) {
      onResume(id);
    } else {
      onPause(id);
    }
  }

  return <>
    <Popconfirm
      title={`${active ? 'Pause' : 'Resume'} the task?`}
      okText={t('action.ok', 'Ok')}
      cancelText={t('action.cancel', 'Cancel')}
      onConfirm={() => onChangeState(!active)}
    >
      <StyledButton
        title={`${active ? 'Pause' : 'Resume'} the task`}
        icon={active ? <PauseCircleOutlined /> : <PlayCircleOutlined />}
      />
    </Popconfirm>
    { onRemove &&
      <Popconfirm
        title={t('action.remove', 'Remove') + '?'}
        okText={t('action.ok', 'Ok')}
        cancelText={t('action.cancel', 'Cancel')}
        onConfirm={() => onRemove(id)}
      >
        <StyledButton
          title={t('action.remove', 'Remove')}
          icon={<CloseCircleOutlined />}
        />
      </Popconfirm>
    }
  </>;
}

ScheduledTaskActions.propTypes = {
  task: PropTypes.object.isRequired,
  onPause: PropTypes.func.isRequired,
  onResume: PropTypes.func.isRequired,
  onRemove: PropTypes.func,
};
