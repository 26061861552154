import React from 'react';
import PropTypes from 'prop-types';

import './RecommendedBadge.scss';

export const RecommendedBadge = ({ text = 'recommended' }) => {
  return <span className='recommendedBadge'>{text}</span>;
};

RecommendedBadge.propTypes = {
  text: PropTypes.string,
};
