import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { DownloadOutlined } from '@ant-design/icons';

export default function StartTaskButton({ onClick, loading, disabled, title=null, icon = <DownloadOutlined/> }) {
  const { t } = useTranslation();

  return (
    <Button
      className='start-task'
      style={{ minWidth: 150 }}
      type='primary'
      size='large'
      icon={icon}
      onClick={onClick}
      disabled={disabled}
      loading={loading}
    >{title ? title : t('title.startTask', 'Get Data')}</Button>
  );
}

StartTaskButton.propTypes = {
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  title: PropTypes.string,
  icon: PropTypes.object,
};
