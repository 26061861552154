import React, { useEffect, useState } from 'react';
import { Table, Empty, notification } from 'antd';
import { useTranslation, Trans } from 'react-i18next';
import config from 'react-global-configuration';

import apiTasks from '../../api/apiTasks';
import Href from '../../shared/Components/Href';

const API_TASK_RESULTS = `${window.location.protocol}//${config.get('apiDomain')}/requests/`;
const LATEST_TASKS = 100;
const LATEST_HOURS = 24;

export default function ApiUsage() {
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const tasksColumns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      render: id => <Href external href={`${API_TASK_RESULTS}${id}`}>{id}</Href>
    },
    {
      title: t('title.usageRecord', 'Usage'),
      dataIndex: 'usage',
      key: 'usage',
      render: usage => <>{usage}</>,
    },
  ];

  useEffect(() => {
    document.title = t('title.apiUsage', 'API Usage History') + ' | ' + config.get('company');
    onFetchData();
  }, []);

  async function onFetchData() {
    setLoading(true);
    try {
      const { data } = await apiTasks.fetchFinishedTasks(100);
      setTasks(data);
    } catch (error) {
      notification.error({ message: error.message });
    } finally {
      setLoading(false);
    }
  }

  return <>
    <h2><Trans i18nKey='title.latestFinishedTasks'>The Latest {{LATEST_TASKS}} Finished Tasks During The Last {{LATEST_HOURS}} Hours</Trans></h2>
    <br/>

    <Table
      rowKey='id'
      size='small'
      scroll={{ y: 800 }}
      loading={loading}
      pagination={false}
      columns={tasksColumns}
      dataSource={tasks}
      locale={{ emptyText: <Empty description={<>You have no finished tasks during the last 24 hours. Navigate to <a href='/api-docs'>API docs</a> to learn more on how to create API tasks.</>} /> }}
    />
    <br/>
    <p className='help-p1'>
    * Each response is available for <strong>2 hours</strong> after a request has been completed. Once the response is expired the status will be <strong>Pending</strong> again.
      <br/>
    * You can fetch responses before the expiration time by request ID, or use <a href='/integrations'>webhooks</a> to get the data once it&apos;s ready.
    </p>
  </>;
}
