export const colors = {
  deepPurple : '#342C5A',
  darkBlue: '#15202B',
  white: '#ffffff',
  mutedWhite: '#f7f9f9',
  accentBlue: '#2A66CF',
  steelBlue: '#384757',
  softGrayWhite: 'rgba(239, 243, 244, 0.1)',
  transparentBlack: 'rgba(0, 0, 0, 0.06)',
};
