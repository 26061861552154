/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';

import { Tooltip } from 'antd';
import { QuestionCircle } from '../../assets/icons';

function InfoTooltip({ title, marginLeft= 0, marginRight= 0 }) {
  return <Tooltip className='alignCenter' title={title}><QuestionCircle style={{ marginLeft, marginRight }}/></Tooltip>;
}

InfoTooltip.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  marginLeft: PropTypes.number,
  marginRight: PropTypes.number,
};

export default React.memo(InfoTooltip, (prevProps, nextProps) => {
  return true;
});
