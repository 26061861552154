import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { Button, Form, Result, Divider, notification } from 'antd';
import { useTranslation, Trans } from 'react-i18next';
import { isEmpty } from 'lodash';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import styled from 'styled-components';
import config from 'react-global-configuration';
import { GoogleLogin } from '@react-oauth/google';

const hcaptchaSiteKey = config.get('hcaptchaSiteKey');

const StyledButton = styled(Button)`
  border-radius: 4px;
`;

const StyledGoogleLoginDiv = styled.div``;

const StyledDiv = styled.div`
  width: 350px;
  margin: auto;
  margin-top: 50px;
  background-color: white;
`;

const StyledSubDiv = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;

export default function LoginSignupForm({ children, location, onFinish, title, subTitle, AfterButtonComponent, loading, checkEmail, captcha = false, skipLogin = true, onGoogleLogin = null, submitButtonTitle = null }) {
  const { search } = location;
  const { redirect, email, utm_source } = queryString.parse(search);
  const [cachePayload, setCachePayload] = useState(null);
  const [showCaptcha, setShowCaptcha] = useState(false);
  const { t } = useTranslation();
  const hcaptchaRef = useRef(null);

  if (redirect) localStorage.setItem('redirect', redirect);
  if (utm_source) localStorage.setItem('source', utm_source);

  useEffect(() => {
    document.title = subTitle;

    if (skipLogin && localStorage.getItem('isLoggedIn') === '1') {
      window.location.replace(redirect || '/');
    }
  }, []);

  function toLogin(){
    window.location.replace('/login');
  }

  function handleOnFinish(payload) {
    if (captcha) {
      if (isEmpty(cachePayload)) {
        setCachePayload(payload);
        setShowCaptcha(true);
        hcaptchaRef.current.execute();
      } else {
        onFinish({ ...cachePayload, ...payload });
      }
    } else {
      onFinish(payload);
    }
  }

  function handleCaptchaVerify(value) {
    onFinish({ ...cachePayload, 'captcha': value });
    clearCachePayload();
  }

  function clearCachePayload() {
    setCachePayload(null);
    setShowCaptcha(false);
  }


  function onGoogleLoginSuccess({ credential }) {
    if (onGoogleLogin) onGoogleLogin(credential);
  }

  function onGoogleLoginFailure() {
    notification.error({ message: 'Failed' });
  }

  return <StyledDiv>
    {checkEmail ?
      <Result
        status='success'
        title={t('title.checkInbox', 'Check Your Inbox')}
        subTitle={<><Trans i18nKey='description.checkEmail'>We&apos;ve sent you an email to</Trans> <strong>{checkEmail}</strong></>}
        extra={[
          <StyledButton block type='primary' key='login' onClick={toLogin}><Trans i18nKey='title.logIn'>Log In</Trans></StyledButton>,
        ]}
      />
      : <>
        <StyledSubDiv>
          <img src='https://outscraper.com/files/logo-cube-inverted.png' alt='Outscraper Logo' width='100' />
          <br/><br/>
          <h1>{title}</h1>
          <p>{subTitle}</p>
        </StyledSubDiv>
        <Form name='login' className='login-form' initialValues={{ remember: true, email }} onFinish={handleOnFinish}>
          {children}
          <Form.Item>
            <StyledButton block type='primary' htmlType='submit' loading={loading} disabled={showCaptcha}>
              {submitButtonTitle ? submitButtonTitle : <Trans i18nKey='title.continue'>Continue</Trans>}
            </StyledButton>
            {onGoogleLogin && <StyledGoogleLoginDiv>
              <Divider plain><Trans i18nKey='title.or'>or</Trans></Divider>
              <GoogleLogin
                onSuccess={onGoogleLoginSuccess}
                onError={onGoogleLoginFailure}
                text='continue_with'
                width='350'
                size='medium'
              />
            </StyledGoogleLoginDiv>}
          </Form.Item>
          <HCaptcha
            size='invisible'
            ref={hcaptchaRef}
            sitekey={hcaptchaSiteKey}
            onVerify={handleCaptchaVerify}
            onExpire={clearCachePayload}
            onClose={clearCachePayload}
          />
        </Form>
        {AfterButtonComponent && <span style={{ textAlign: 'center', marginTop: '20px' }}>
          <AfterButtonComponent />
        </span>}
      </>
    }
  </StyledDiv>;
}

LoginSignupForm.propTypes = {
  children: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  onFinish: PropTypes.func.isRequired,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  AfterButtonComponent: PropTypes.func,
  loading: PropTypes.bool,
  checkEmail: PropTypes.string,
  captcha: PropTypes.bool,
  skipLogin: PropTypes.bool,
  onGoogleLogin: PropTypes.func,
  submitButtonTitle: PropTypes.string,
};
