import React from 'react';

import { services } from '../../shared/data/services';
import SimpleService from './Common/SimpleService';

export default function CompanyInsights() {
  return (
    <SimpleService
      limitType={null}
      unitName={services.company_insights_service.unit}
      title={services.company_insights_service.name}
      subTitle={services.company_insights_service.description}
      serviceName='company_insights_service'
      queriesPlaceholder='company.com&#10;company2.com'
      queriesLabel='Domains or URLs'
      apiTag='Other-Services/paths/~1company-insights/get'
      enrichmentFields={services.company_insights_service.input_fields}
      supportedEnrichmentServices={[]}
    />
  );
}
