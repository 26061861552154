import React from 'react';

import SimpleService from './Common/SimpleService';

export default function BookingSearchScraper() {
  return (
    <SimpleService
      unitName='record'
      title='Booking Search Scraper'
      subTitle='Returns search results'
      queriesLabel='Search URLs'
      serviceName='booking_search_service'
      learnMoreUrl='https://outscraper.com/booking-scraper/'
      queriesPlaceholder='https://www.booking.com/searchresults.html?ss=Rome%2C+Lazio%2C+Italy'
      apiTag='Booking'
    />
  );
}
