import React from 'react';

import { services } from '../../shared/data/services';
import SimpleService from './Common/SimpleService';

export default function WhitepagesAddresses() {
  return (
    <SimpleService
      limitType={null}
      unitName={services.whitepages_addresses.unit}
      title={services.whitepages_addresses.name}
      subTitle={services.whitepages_addresses.description}
      serviceName='whitepages_addresses'
      queriesLabel='Addresses'
      queriesPlaceholder='321 California Ave, Palo Alto, CA 94306'
      apiTag='Others/paths/~1whitepages-addresses/get'
      enrichmentFields={services.whitepages_addresses.input_fields}
      fields={services.whitepages_addresses.output_fields}
      supportedEnrichmentServices={[]}
    />
  );
}
