import axios from 'axios';
import { get } from 'lodash';
import config from 'react-global-configuration';

axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

const instance = axios.create({ baseURL: `${window.location.protocol}//${config.get('apiDomain')}` });

instance.interceptors.response.use(response => {
  if (response.data.error) {
    throw new Error(response.data.errorMessage);
  }

  return response;
}, error => {
  if (typeof error.response === 'undefined') {
    throw new Error(error.message);
  }

  if (error.response.status === 401) {
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('expiresAt');
    localStorage.removeItem('idToken');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('email');

    const { pathname, search = '' } = window.location;
    window.location = `/login?redirect=${encodeURIComponent(pathname + search)}`;
  } else if (get(error, 'response.data.error') && get(error, 'response.data.errorMessage')) {
    throw new Error(`${error.response.data.errorMessage}`);
  } else if (error.response.status === 403) {
    throw new Error('Permission denied');
  } else if (error.response.status === 405) {
    throw new Error('Method not allowed');
  } else if (error.response.status === 409) {
    throw new Error('Conflict');
  } else if (error.response.status === 410) {
    throw new Error('Not found or removed');
  } else if (error.response.status === 502) {
    throw new Error('Server error');
  } else {
    throw new Error(error);
  }

  console.error(error);
  return error;
});

export default instance;
