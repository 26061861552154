import {
  START_REQUEST,
  STOP_REQUEST,
  FETCH_INVOICES_SUCCESS,
} from '../actions/paymentActions';

export default (state = { invoices: [], hasMoreInvoices: false, loading: false }, action) => {
  switch (action.type) {
  case START_REQUEST: {
    return {
      ...state,
      loading: true,
    };
  }
  case STOP_REQUEST: {
    return {
      ...state,
      loading: false,
    };
  }
  case FETCH_INVOICES_SUCCESS: {
    return {
      ...state,
      invoices: action.invoices,
      hasMoreInvoices: action.hasMoreInvoices,
    };
  }
  default:
    return state;
  }
};
