import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Modal, Button, Result, Segmented, Table } from 'antd';
import { UploadOutlined, FileTextOutlined, FileAddOutlined } from '@ant-design/icons';
import { useTranslation, Trans } from 'react-i18next';
import PremiumFeatureTooltip from '../../../shared/Components/PremiumFeatureTooltip';
import { isPaidUser } from '../../../utils/utils';

import FileUploader from './FileUploader';

export default function UploadFile({ onReady, enrichmentFields, placeholder }) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [enrich, setEnrich] = useState(false);
  const paidUser = isPaidUser();

  const { t } = useTranslation();

  const uplaodQueriesTitle = t('action.uploadQueries', 'Upload queries');
  const enrichFileTitle = t('action.enrichFile', 'Enrich file');
  const modalTitle = (enrich ? enrichFileTitle : uplaodQueriesTitle);
  const fileTypes = 'CSV/XLSX/Parquet';

  function showModal() {
    setIsModalVisible(true);
  }

  function handleCancel() {
    setIsModalVisible(false);
  }

  function onFileUploaded(fileUrl) {
    onReady(fileUrl, enrich, fileUrl.split('.').pop());
    setIsModalVisible(false);
  }

  function onTypeChange(value) {
    setEnrich(value);
  }

  const placeholderRows = placeholder ? placeholder.split(/\r?\n/) : [];
  const columns = [{ dataIndex: 'query', key: 'query' }];
  const dataSource = placeholderRows.map((query, index) => ({ key: index, query }));
  if (dataSource.length < 3) {
    dataSource.push({ key: '...', query: '...' });
  }

  return <>
    <PremiumFeatureTooltip isPaidUser={paidUser}>
      <Button
        type='dashed'
        size='small'
        disabled={!paidUser}
        title={modalTitle}
        icon={<UploadOutlined />}
        onClick={showModal}
      > <Trans i18nKey='action.upload'>Upload file</Trans></Button>
    </PremiumFeatureTooltip>

    <Modal
      title={modalTitle}
      footer={null}
      open={isModalVisible}
      onCancel={handleCancel}
      style={{ top: 60 }}
    >
      <Result
        icon={enrich ? <FileAddOutlined /> : <FileTextOutlined />}
        subTitle={<>
          {!isEmpty(enrichmentFields) && <Segmented
            block
            defaultValue={enrich}
            onChange={onTypeChange}
            options={[
              { label: uplaodQueriesTitle, value: false, icon: <FileTextOutlined /> },
              { label: enrichFileTitle, value: true, icon: <FileAddOutlined /> }
            ]}
          />}
          <br/>
          {enrich ? <>
            Upload your {fileTypes} file that you want to extend.
            Ensure your file contains headers that the platform can recognize. {enrichmentFields ? <><strong>Valid headers</strong>: {enrichmentFields.join(' / ')}.</> : '!'}
            <br/><br/>Visit <a href='https://outscraper.com/enrich-companies-data-with-google/?utm_source=UI' target='_blank' rel='noopener noreferrer'>this article</a> to learn more about how to enrich your file.
          </> : <>
            Upload your queries from a {fileTypes} file in a single column without a header, containing only queries, for example:
            {placeholder && <>
              <br/><br/>
              <div style={{ overflowX: 'auto' }}>
                <Table
                  bordered
                  size='small'
                  style={{ width: '100%' }}
                  pagination={false}
                  showHeader={false}
                  columns={columns}
                  dataSource={dataSource}
                />
              </div>
            </>}
          </>}
        </>}
        extra={<FileUploader onFileUploaded={onFileUploaded} />}
      />
    </Modal>
  </>;
}

UploadFile.propTypes = {
  onReady: PropTypes.func.isRequired,
  enrichmentFields: PropTypes.array,
  placeholder: PropTypes.string,
};
