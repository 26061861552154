import React from 'react';

import { PHONE_INPUT_FIELDS } from '../../../shared/data/services';
import SimpleService from '../Common/SimpleService';

export default function ImessageChecker() {
  return (
    <SimpleService
      beta
      unitName='phone'
      title='iMessage Checker'
      subTitle='Checks whether a phone is connected to the service'
      serviceName='imessage_checker'
      queriesLabel='Phone numbers (international)'
      queriesPlaceholder='+1 281 236 8208&#10;442812368208'
      limitType={null}
      enrichmentFields={PHONE_INPUT_FIELDS}
      supportedEnrichmentServices={[]}
    />
  );
}
