import React from 'react';
import PropTypes from 'prop-types';

import './PriceRange.scss';

export const PriceRange = ({ service }) => {
  const prices = service.prices?.map(price => price.unit_amount_decimal);

  const minPrice = Math.min(...prices);
  const maxPrice = Math.max(...prices);

  return <div className='priceRange'>
    <span className='price'>{`$${minPrice}-${maxPrice}`}</span>
    <span className='unit'>{`/${service.unit}`}</span>
  </div>;
};

PriceRange.propTypes = {
  service: PropTypes.object.isRequired,
};
