import { combineReducers } from 'redux';

import queriesReducer from './queriesReducer';
import profileReducer from './profileReducer';
import tasksReducer from './tasksReducer';
import paymentReducer from './paymentReducer';

export default combineReducers({
  queriesReducer,
  profileReducer,
  tasksReducer,
  paymentReducer,
});
