import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { RedocStandalone } from 'redoc';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import config from 'react-global-configuration';

import { fetchProfile } from '../../redux/actions/profileActions';

const DEFAULT_API_KEY = 'YOUR-API-KEY';

export default function ApiDocs({ auth }) {
  const profile = useSelector(state => state.profileReducer.profile);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { api_token } = profile;
  const [spec, setSpec] = useState({});
  const [specEmpty, setSpecEmpty] = useState(true);
  const profileLoaded = !isEmpty(profile);

  useEffect(() => {
    document.title = t('title.apiDocs', 'API Docs') + ' | ' + config.get('company');

    if (specEmpty) {
      if (profileLoaded) {
        if (api_token) replaceSpec(api_token);
      } else {
        if (auth.authenticated) dispatch(fetchProfile());
      }
    }
  }, [api_token]);

  async function replaceSpec(newApiKey) {
    const data = await fetch('./api-docs-data.json');
    let text = await data.text();

    setSpec(JSON.parse(text.replaceAll(DEFAULT_API_KEY, newApiKey)));
    setSpecEmpty(false);
  }

  return (
    <RedocStandalone
      specUrl={specEmpty ? '/api-docs-data.json' : undefined}
      spec={specEmpty ? undefined : spec}
      options={{
        nativeScrollbars: true,
        theme: { colors: { primary: { main: '#0C409D' } } },
      }}
    />
  );
}

ApiDocs.propTypes = {
  auth: PropTypes.object.isRequired,
};
