import React from 'react';

import { services } from '../../shared/data/services';
import SimpleService from './Common/SimpleService';

export default function WhitepagesPhones() {
  return (
    <SimpleService
      limitType={null}
      unitName={services.whitepages_phones.unit}
      title={services.whitepages_phones.name}
      subTitle={services.whitepages_phones.description}
      serviceName='whitepages_phones'
      queriesLabel='US phone numbers'
      apiTag='Phone-Related/paths/~1whitepages-phones/get'
      queriesPlaceholder='1 281 236 8208&#10;1 281 236 2248'
      enrichmentFields={services.whitepages_phones.input_fields}
      supportedEnrichmentServices={[]}
    />
  );
}
