import React from 'react';

import SimpleService from './Common/SimpleService';

export default function ExpediaSearchScraper() {
  return (
    <SimpleService
      unitName='record'
      title='Expedia Search Scraper'
      subTitle='Returns search results'
      queriesLabel='Search URLs'
      serviceName='expedia_search'
      learnMoreUrl='https://outscraper.com/expedia-scraper/'
      queriesPlaceholder='https://www.expedia.com/Hotel-Search?destination=Ankara...'
      apiTag='Expedia'
    />
  );
}
