import React from 'react';
import PropTypes from 'prop-types';
import { Form, Checkbox, DatePicker, Select, Space } from 'antd';
import dayjs from 'dayjs';

import ReviewsSortSelect from '../Selects/ReviewsSortSelect';
import InfoTooltip from '../../../../shared/Components/InfoTooltip';

const { Option } = Select;

export const reviewsSorts = [
  { value: 'most_relevant', text: 'Most relevant' },
  { value: 'newest', text: 'Newest' },
  { value: 'rating', text: 'Rating' },
];

export default function ReviewsSortingFields({ task, onChange }) {
  const { cutoff, rating, sort } = task;

  const useCutoff = cutoff !== null;

  function disabledDate(current) {
    return current && current > dayjs();
  }

  function onSetUseCutoff() {
    onChange({ ...task, cutoff: cutoff ? null : dayjs().startOf('day') });
  }

  function onSetCutoff(value) {
    onChange({ ...task, cutoff: value });
  }

  function onSetSort(value) {
    onChange({
      ...task,
      sort: value,
      cutoff: value !== 'newest' ? null : cutoff,
      rating: value === 'rating' ? rating : null,
    });
  }

  return <>
    <Form.Item className='formItem'>
      <Form.Item label='Sorting' className='inlineFormItem'>
        <Space align='center'>
          <ReviewsSortSelect onChange={onSetSort} value={sort} options={reviewsSorts}/>
          <InfoTooltip title='Parameter specifies one of the sorting types.'/>
        </Space>
      </Form.Item>

      {sort === 'newest' &&
        <Form.Item label='Cutoff' className='inlineFormItem'>
          <Checkbox checked={useCutoff} onChange={() => onSetUseCutoff()}>
            Stop at specific date (UTC)
          </Checkbox>
          {useCutoff &&
            <DatePicker
              showTime
              format='YYYY/MM/DD HH:mm:ss'
              value={cutoff}
              onChange={onSetCutoff}
              disabledDate={disabledDate}
            />
          }
        </Form.Item>
      }

      {sort === 'rating' &&
      <>
        <Form.Item label='Rating' className='inlineFormItem'>
          <Select
            style={{ width: 200 }}
            value={rating}
            optionFilterProp='children'
            onChange={(v) => onChange({ ...task, rating: v })}
          >
            <Option key='all' value={null}>All Ratings</Option>
            <Option key='1' value={1}>1-star</Option>
            <Option key='2' value={2}>2-star</Option>
            <Option key='3' value={3}>3-star</Option>
            <Option key='4' value={4}>4-star</Option>
            <Option key='5' value={5}>5-star</Option>
          </Select>
          <InfoTooltip title='Filter by a specific rating'/>
        </Form.Item>
        <p className='warning'>Sorting type &quot;rating&quot; works only for Apps</p>
      </>
      }
    </Form.Item>
  </>;
}

ReviewsSortingFields.propTypes = {
  task: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};
