import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import config from 'react-global-configuration';

import { Popover, Progress, Badge } from 'antd';

const taskExpiredAfter = config.get('taskExpiredAfter');
const statusesMap = {
  'PROGRESS': 'active',
  'STARTED': 'active',

  'RECEIVED': 'active',
  'PENDING': 'active',
  'UNKNOWN': 'active',
  'RETRY': 'active',

  'SUCCESS': 'success',
  'RESTARTED': 'active',

  'FAILURE': 'exception',
  'REVOKED': 'exception',
  'WARNING': 'exception',
};
const EXPIRED_STATUS = 'SUCCESS';

export default function TaskProgress({ task = {} }) {
  const { created, metadata } = task;
  const { est } = metadata || {};
  const timestampNow = Date.now() / 1000;
  const timestampCreated = Date.parse(created + 'Z') / 1000;
  const expired = timestampNow - timestampCreated > taskExpiredAfter * 60 * 60 * 24;
  const { progress, stage, status, updated_at, traceback } = task;
  const componentStatus = statusesMap[status];
  const isActive = statusesMap[status] === 'active';
  const finishAt = est && isActive ? timestampCreated + est * 2 * 60 : null;

  function  getStatusDescription(status, stage) {
    if (status) {
      if (status === 'PENDING') {
        return `${status}: waiting for available server, will be started shortly`;
      } else if (status === 'PROGRESS' || status === 'STARTED') {
        return <Badge status='processing' text={stage ? stage : `${status}: task is running`}/>;
      } else if (status === 'FAILURE') {
        return `${status}: ${traceback ? traceback : 'there is a problem during executing the task, please try to recreate the task'}`;
      } else if (status === 'WARNING' || status === 'REVOKED') {
        return `${status}: server error, task restart is required`;
      } else if (status === 'UNKNOWN') {
        return `${status}: task status temporary unknown`;
      } else if (status === 'RETRY') {
        return `${status}: restarting the task due to the server problem`;
      } else if (status === 'RESTARTED') {
        return `${status}: task will be started with the new ID`;
      }
    }

    return status;
  }

  function getProgress(status, progress) {
    if (status === 'SUCCESS') {
      return 100;
    } else if (status === 'STARTED') {
      return 100;
    } else if (progress > 20 || status === 'PENDING' || status === 'RECEIVED') {
      return progress;
    } else {
      return 20;
    }
  }

  return (
    <Popover content={
      <>
        {getStatusDescription(status, stage)}
        {updated_at && <> - {isActive ? 'running well, ': ''}checked <Moment unix local fromNow>{updated_at}</Moment>.
          {finishAt && finishAt > timestampNow && <> Estimated finish <Moment unix local fromNow>{finishAt}</Moment>.</>}
        </>}
      </>} title={false}>
      <Progress
        size='small'
        percent={getProgress(status, progress)}
        status={expired ? EXPIRED_STATUS : componentStatus}
        format={isActive ? () => '' : null}
      />
    </Popover>
  );
}

TaskProgress.propTypes = { task: PropTypes.object.isRequired };
