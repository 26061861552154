import React from 'react';

import SimpleService from '../Common/SimpleService';

export default function HubSpotContactEmailsFinder() {
  return (
    <SimpleService
      beta
      unitName='email'
      title='HubSpot Contact Emails Finder'
      subTitle='Enrich contacts with their email addresses'
      serviceName='hubspot_contact_emails_enricher_service'
      startTaskButtonTitle='Enrich Contacts'
      limitType={null}
      showParams={false}
      isTaskReady={() => true}
      requiredIntegratoion={'HubSpot'}
      learnMoreUrl='https://outscraper.com/hubspot-enrichments/'
      defaultParams={{queries: 'contacts', integration_data_path: 'hubspot.refresh_token'}}
      ExtraItems={() => {
        return <>
          * Make sure your contacts on HubSpot have full names and company websites associated with them. The service will try to find emails for all contacts without emails and update them.
          <br/>
          <br/>
        </>;
      }}
    />
  );
}
