import React from 'react';
import { Form, InputNumber, Space } from 'antd';
import qs from 'query-string';
import { cloneDeep, isEmpty, debounce } from 'lodash';
import { useSelector } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import config from 'react-global-configuration';
import PropTypes from 'prop-types';

import history from '../../../utils/history';
import { getDefaultRegion, onSetDefaultRegion, getDefaultLanguage, getDefaultExactMatch } from '../../../utils/defaultProps';
import { formatTaskQueries, deformatTaskQueries, isMapsTaskReady } from '../Common/Google/utils';
import { splitTaskByKey, addUrlParams, toArray } from '../../../utils/utils';
import InfoTooltip from '../../../shared/Components/InfoTooltip';
import BaseService from '../Common/BaseService';
import QueriesFormItems from '../Common/Google/QueriesFormItems';
import EnrichmentListSelector from '../Enrichments/EnrichmentListSelector';
import RegionSelect from '../Common/Google/RegionSelect';
import { enrichmentFields, SERVICES } from '../../../shared/constants/googleMaps.constants';
import { AdvancedParameters } from './components/AdvancedParameters/AdvancedParameters';

const queriesSoftLimit = config.get('queriesSoftLimit');
const LOCATIONS_DELIMITER = '>';


export const CUSTOM_QUERIES_KEY = 'customQueries';

export const GoogleMapsScraperColumns = [
  'query', 'name', 'name_for_emails', 'site', 'subtypes', 'category', 'type', 'phone',
  'full_address', 'borough', 'street', 'city', 'postal_code', 'area_service', 'state', 'us_state', 'country', 'country_code', 'latitude', 'longitude', 'h3', 'time_zone', 'plus_code',
  'rating', 'reviews', 'reviews_link', 'reviews_tags', 'reviews_per_score', 'reviews_per_score_1', 'reviews_per_score_2', 'reviews_per_score_3', 'reviews_per_score_4', 'reviews_per_score_5',
  'photos_count', 'photo', 'street_view', 'located_in',
  'working_hours', 'working_hours_old_format', 'other_hours', 'popular_times', 'business_status', 'about', 'range', 'posts', 'logo', 'description', 'typical_time_spent',
  'verified', 'owner_id', 'owner_title', 'owner_link',
  'reservation_links', 'booking_appointment_link', 'menu_link', 'order_links',
  'location_link',
  'place_id', 'google_id', 'cid', 'reviews_id', 'located_google_id'
];

export default function GoogleMapsScraper({ version }) {
  const {
    hl: urlLanguage, query: urlQuery, gl: urlRegion, c: urlCategories, l: urlLocations,
    e: urlEnrichments, z: urlUseZipCodes, queryLimit: urlQueryLimit, limit: urlLimit,
    dd: urlDropDuplicates,
  } = qs.parse(history.location.search);
  const defaultLanguage = getDefaultLanguage(urlLanguage);
  const defaultRegion = urlRegion ? urlRegion : getDefaultRegion(urlRegion) || 'US';
  const defaultEnrichments = urlEnrichments ? toArray(urlEnrichments) : localStorage.getItem('googleMapsEnrichments') ? JSON.parse(localStorage.getItem('googleMapsEnrichments')) : (localStorage.getItem('appsumo') ? [SERVICES.domainsService] : [SERVICES.domainsService, SERVICES.companyInsightsService, SERVICES.emailsValidatorService, SERVICES.phonesEnricherService, ...(defaultRegion === 'US' ? [SERVICES.whitepagesPhonesService] : [])]);
  const taskExtraDefaultParams = {
    categories: urlCategories ? toArray(urlCategories) : [],
    locations: urlLocations ? toArray(urlLocations) : [],

    enrichments: defaultEnrichments,

    customCategories: '',
    customLocations: '',

    language: defaultLanguage,
    region: defaultRegion,

    limit: parseInt(urlLimit) || 0,
    organizationsPerQueryLimit: parseInt(urlQueryLimit) || 500,

    filters: [],
    exactMatch: defaultLanguage === 'en' ? getDefaultExactMatch(false) : false,
    useZipCodes: urlUseZipCodes === 'true',

    dropDuplicates: urlDropDuplicates === 'true' ? urlDropDuplicates : localStorage.getItem('dropDuplicates') !== '0',
    dropEmailDuplicates: false,
    ignoreWithoutEmails: false,

    UISettings: {
      isCustomQueries: urlQuery  ? true : (localStorage.getItem(CUSTOM_QUERIES_KEY) === '1' && !urlCategories && !urlLocations),
      isCustomCategories: false,
      isCustomLocations: false,
    },
  };

  const countriesLocations = useSelector(state => state.queriesReducer.countryLocations) || {};
  const { t } = useTranslation();
  let updateTimer = null;

  function formatTask(task) {
    const { UISettings = {}, settings = {}, enrich } = task;
    const { output_columns = [] } = settings;
    settings.output_columns =  [...new Set(output_columns)];

    const { isCustomQueries, isCustomCategories, isCustomLocations } = UISettings;

    if (enrich) {
      task.filters = [];
      task.organizationsPerQueryLimit = 1;
      task.dropDuplicates = false;
      task.dropEmailDuplicates = false;
      task.ignoreWithoutEmails = false;
      task.region = undefined;
    }

    return formatTaskQueries({
      ...task, limit: task.limit || 0,
    }, isCustomQueries, isCustomCategories, isCustomLocations);
  }

  function deformatTask(task) {
    const { UISettings = {} } = task;
    const { isCustomQueries, isCustomCategories, isCustomLocations } = UISettings;
    return deformatTaskQueries(task, isCustomQueries, isCustomCategories, isCustomLocations);
  }

  function splitTask(task, splitInto) {
    if (!isEmpty(task.queries)) {
      return splitTaskByKey(task, splitInto, 'queries');
    } else if (!isEmpty(task.locations) && !isEmpty(task.categories)) {
      const { locations, categories, useZipCodes, region, UISettings } = task;
      const { isCustomLocations } = UISettings;

      if (isCustomLocations) return splitTaskByKey(task, splitInto, 'locations');

      const results = [];
      const countryLocations = countriesLocations[region].reduce((a, b) => ({ ...a, [b.value]: b.children }), {});
      const categoriesAmount = categories.length;
      let oneTaskLocations = [];
      let oneTaskQueriesAmount = 0;

      for (const location of locations) {
        const locationQueriesAmount = getTotalSubLocationsAmount(location, useZipCodes, countryLocations) * categoriesAmount;
        const subLocations = (locationQueriesAmount < queriesSoftLimit) ? [location] : getSubLocations(location, countryLocations);

        for (const subLocation of subLocations) {
          const subLocationQueriesAmount = getTotalSubLocationsAmount(subLocation, useZipCodes, countryLocations) * categoriesAmount;

          if (oneTaskQueriesAmount + subLocationQueriesAmount < queriesSoftLimit) {
            oneTaskLocations.push(subLocation);
            oneTaskQueriesAmount += subLocationQueriesAmount;
          } else {
            results.push(cloneSubTask(task, oneTaskLocations, results.length));
            oneTaskLocations = [subLocation];
            oneTaskQueriesAmount = subLocationQueriesAmount;
          }
        }
      }

      if (oneTaskLocations.length > 0) results.push(cloneSubTask(task, oneTaskLocations, results.length));

      return results;
    }
  }

  function cloneSubTask(task, locations, index) {
    const newTask = cloneDeep(task);
    const { tags = [] } = newTask;

    const formatedTags = typeof tags === 'string' ? tags.split(', ') : tags;
    const locationsTag = locations.length == 1 ? locations[0] : `${locations[0]}...${locations[locations.length - 1]}`;

    return {
      ...newTask,
      tags: [...formatedTags, `subtask #${index + 1} (${locationsTag})`],
      locations,
    };
  }

  function getTotalSubLocationsAmount(location, useZipCodes, countryLocations) {
    if (location in countryLocations) {
      if (useZipCodes) {
        return countryLocations[location].reduce((a, b) => a + b.children.length, 0);
      } else {
        return countryLocations[location].length;
      }
    } else {
      const locationParts = location.split(LOCATIONS_DELIMITER);

      if (locationParts.length === 4) {
        return 1;
      } else if (locationParts.length === 3) {
        if (!useZipCodes) {
          return 1;
        } else {
          for (const subLocation of countryLocations[locationParts.slice(0, 2).join(LOCATIONS_DELIMITER)]) {
            if (subLocation.value === location) return subLocation.children.length;
          }
        }
      }

      return 0;
    }
  }

  function getSubLocations(location, countryLocations) {
    if (location in countryLocations) {
      return countryLocations[location].map(r => r.value);
    } else {
      const locationParts = location.split(LOCATIONS_DELIMITER);
      for (const subLocation of countryLocations[locationParts.slice(0, 2).join(LOCATIONS_DELIMITER)]) {
        if (subLocation.value === location) return subLocation.children.map(r => r.value);
      }
      return [location];
    }
  }

  function updateUrlParams(params) {
    const { UISettings = {}, categories, locations, region, language, enrichments, limit, dropDuplicates, useZipCodes } = params;
    const { isCustomCategories, isCustomLocations } = UISettings;

    const urlParams = {
      c: (categories && categories.length <= 25 && !isCustomCategories) ? categories : [],
      l: (locations && locations.length <= 25 && !isCustomLocations) ? locations : [],
      e: (enrichments && enrichments.length <= 25) ? enrichments : [],
      z: useZipCodes ? true : [],
      limit: limit ? limit : [],
      dd: dropDuplicates ? true : [],
      gl: region ? region : [],
      hl: language ? language : [],
    };

    window.history.replaceState(null, null, `?${addUrlParams(location.search, urlParams)}` );
  }

  return (
    <BaseService
      title='Google Maps Data Scraper'
      subTitle='Returns places data from Google Maps'
      videoTutorialLink='https://www.youtube.com/embed/mUxWvsksGJ8'
      tutorialLink='https://outscraper.com/how-to-scrape-google-maps'
      serviceName={version === 2 ? 'google_maps_service_v2' : 'google_maps_service'}
      unitName='place'
      tourSteps={[
        {
          target: () => document.querySelector('.categories-select'),
          title: t('tour.categoriesSelect', 'The first step will be selecting categories or brands of places you want to export.'),
        },
        {
          target: () => document.querySelector('.locations-select'),
          title: t('tour.locationsSelect', 'Secondly, you need to select locations where you want to search for places.'),
        },
        {
          target: () => document.querySelector('.plain-queries'),
          title: t('tour.plainQueries', 'Alternatively, you can switch to plain queries to search for places as you would search them on Google Maps. However, in most cases, it\'s important to use locations inside queries (e.g., bars, NY, USA) as the IP addresses of Outscraper\'s servers might be located in different countries.'),
        },
        {
          target: () => document.querySelector('.limit-select'),
          title: t('tour.limitSelect', 'Do you want to cap your limit or do you need all the places by your search criteria? You can set the limit here.'),
        },
        {
          target: () => document.querySelector('.enrichments-select'),
          title: t('description.enrichment.title', 'Here you can enrich places with other services') + ':',
          description: <>
            ✉️ <Trans i18nKey='description.enrichment.findEmails'>Find email addresses</Trans><br/>
            🌐 <Trans i18nKey='description.enrichment.socialMedia'>Find social media profiles</Trans><br/>
            📨 <Trans i18nKey='description.enrichment.validateEmails'>Validate emails</Trans><br/>
            ☎️ <Trans i18nKey='description.enrichment.validatePhones'>Validate phones</Trans><br/>
            ...<br/>
            <a target='_blank' rel='noopener noreferrer' href='https://outscraper.com/enrichment-services/'><Trans i18nKey='action.learnMore'>Learn more</Trans></a>
          </>,
        }
      ]}
      fields={GoogleMapsScraperColumns}
      learnMoreUrl='https://outscraper.com/google-maps-scraper'
      apiTag='Google/paths/~1maps~1search-v3/get'
      taskExtraDefaultParams={taskExtraDefaultParams}
      isTaskReady={isMapsTaskReady}
      formatTask={formatTask}
      splitTask={splitTask}
      deformatTask={deformatTask}
      taskUpdateAfterSubmit={{ queries: '', categories: [], customCategories: '', input_file: null, enrich: false, tags: [] }}
      FormBody={({ task, updateTask }) => {
        const { region, enrichments, UISettings = {} } = task;
        const { isCustomQueries, isCustomCategories, isCustomLocations } = UISettings;

        function onUpdateTask(value) {
          clearTimeout(updateTimer);
          updateTimer = setTimeout(() => {
            updateUrlParams({ ...task, ...value });
          }, 1000);

          updateTask(value);
        }

        function setIsCustomCategories(isCustomCategories) {
          onUpdateTask({ UISettings: { ...UISettings, isCustomCategories } });
        }

        function setIsCustomLocations(isCustomLocations) {
          onUpdateTask({ UISettings: { ...UISettings, isCustomLocations } });
        }

        function onRegionChange(newRegion) {
          if (newRegion !== 'US' && ['companies_data', 'whitepages_phones'].some(service => enrichments.includes(service))) {
            const newEnrichments = enrichments.filter(el => !['companies_data', 'whitepages_phones'].includes(el));

            localStorage.setItem('googleMapsEnrichments', JSON.stringify(newEnrichments));
            onUpdateTask({ region: newRegion, enrichments: newEnrichments });
          } else {
            onUpdateTask({ region: newRegion });
          }

          onSetDefaultRegion(newRegion);
        }

        return <>
          <QueriesFormItems
            task={task}
            onUpdate={onUpdateTask}
            isCustomQueries={isCustomQueries}
            isCustomCategories={isCustomCategories}
            isCustomLocations={isCustomLocations}
            onIsCustomCategoriesChange={setIsCustomCategories}
            onIsCustomLocationsChange={setIsCustomLocations}
            customQueriesKey={CUSTOM_QUERIES_KEY}
            enrichmentFields={enrichmentFields}
          />

          {(isCustomLocations || isCustomQueries) &&
            <Form.Item label={t('title.countryCustom', 'Country (data outside of this region will be ignored, necessary to parse postal code)')}>
              <RegionSelect size='large' value={region} onChange={onRegionChange}/>
            </Form.Item>
          }
        </>;
      }}
      FormBodyExtra={({ task, updateTask, isPaidUser }) => {
        const { limit } = task;

        function onUpdateTask(value) {
          clearTimeout(updateTimer);

          updateTimer = setTimeout(() => {
            updateUrlParams({ ...task, ...value });
          }, 500);

          updateTask(value);
        }

        function onEnrichmentChange(enrichments) {
          onUpdateTask({ enrichments });
          localStorage.setItem('googleMapsEnrichments', JSON.stringify(enrichments));
        }

        function onUpdateLimit(value) {
          onUpdateTask({ limit: value });
        }

        const debouncedOnUpdateLimit = debounce(onUpdateLimit, 200);

        const labelWithTooltip = (
          <Space align='center'>
            <h3 className='h3'>{<Trans i18nKey='title.enrichments'>Enhance results with other services (<strong>emails</strong>, social media, more phones, legal names, employees, etc.)</Trans>}</h3>
            <InfoTooltip title={<Trans i18nKey='description.gm.enrichments'>
                There is a limited amount of <a target='_blank' rel='noopener noreferrer' href='https://outscraper.com/google-maps-scraper/#dictionary'>fields</a> you can scrape from Google Maps. However, you can enrich the data with other services by adding the services you need to the scraping pipeline.
              <a target='_blank' rel='noopener noreferrer' href='https://outscraper.com/enrichment-services/'> Learn more</a>
              <br/><br/>

                The most common way of using it is enriching the results with <strong>Email & Contacts Scraper</strong>. This will search other public sources to get emails, contacts, social media, etc.
              <a target='_blank' rel='noreferrer' href='https://outscraper.com/find-contacts-google-place/'> Learn more</a> about how Outscraper bot finds emails.
            </Trans>}/>
          </Space>
        );

        return <>
          <Form.Item label={t('title.maxResults', 'Maximum results limit (0 = unlimited)')} className='limit-select'>
            <Space align='center'>
              <InputNumber
                min={0}
                step={1000}
                value={limit}
                onChange={debouncedOnUpdateLimit}
              />
              <InfoTooltip title={<Trans i18nKey='description.maxResults'>
              Parameter specifies the total limit of organizations that will be extracted
              from all search queries.
                <br/><br/>
              Although we don&apos;t have the exact amount of results before the task is finished, you can check our <a target='_blank' rel='noreferrer' href='https://outscraper.com/targetron'>partners&apos; directory</a>, to get an approximate amount of places by your criteria.
              </Trans>}/>
            </Space>
          </Form.Item>

          <Form.Item label={labelWithTooltip} className='enrichments-select noBottomMargin'>
            <EnrichmentListSelector
              serviceOntologies={['domain', 'phone']}
              task={task}
              updateTask={updateTask}
              onChange={onEnrichmentChange}
            />
          </Form.Item>

          <AdvancedParameters task={task} updateTask={updateTask} isPaidUser={isPaidUser}/>
        </>;
      }}
    />
  );
}

GoogleMapsScraper.propTypes = {
  version: PropTypes.number,
};
