import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';

function CustomItemsSelect({ value, onChange, placeholder, autoFocus, rows = 7 }) {
  function handleOnChange({ target }) {
    onChange(target.value);
  }

  return (
    <Input.TextArea
      size='large'
      autoFocus={autoFocus}
      rows={rows}
      value={value}
      onChange={handleOnChange}
      placeholder={placeholder}
    />
  );
}

CustomItemsSelect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  autoFocus: PropTypes.bool,
  rows: PropTypes.number,
};

export default React.memo(CustomItemsSelect, (prevProps, nextProps) => {
  return prevProps.value === nextProps.value;
});
