export function getTemplateTask() {
  const templateTask = localStorage.getItem('templateTask');

  if (templateTask) {
    localStorage.removeItem('templateTask');
    return JSON.parse(templateTask);
  }
}

export function setTemplateTask(task) {
  localStorage.setItem('templateTask', JSON.stringify(task));
}

export function getDefaultLanguage(defaultLanguage, key = '') {
  return getDefaultValue(defaultLanguage, `${key}language`) || 'en';
}

export function onSetDefaultLanguage(language, key = '') {
  setDefaultValue(language, `${key}language`);
}

export function getDefaultRegion(urlRegion, key = '') {
  return getDefaultValue(urlRegion, `${key}region`);
}

export function getDefaultExactMatch(defaultValue, key = '') {
  const value = getDefaultValue(null, `${key}exactMatch`);
  if (value) {
    return value === '1';
  } else {
    return defaultValue;
  }
}

export function onSetDefaultRegion(region, key = '') {
  setDefaultValue(region, `${key}region`);
}

export function getCustomQueries(key = '') {
  const localStorageValue = localStorage.getItem(`queries-${key}`);
  return localStorageValue ? localStorageValue : '';
}

export function setCustomQueries(queries, key = '') {
  const localKey = `queries-${key}`;

  if (queries) {
    localStorage.setItem(localKey, queries);
  } else {
    localStorage.removeItem(localKey);
  }
}

export function getDefaultValue(defaultValue, localKey) {
  const localStorageValue = localStorage.getItem(localKey);
  if (localStorageValue) {
    return localStorageValue;
  } else {
    if (defaultValue && defaultValue !== 'undefined') return defaultValue;
    else return undefined;
  }
}

export function setDefaultValue(value, localKey) {
  if (value) {
    localStorage.setItem(localKey, value);
  } else {
    localStorage.removeItem(localKey);
  }
}
