import {
  START_REQUEST,
  STOP_REQUEST,
  FETCH_TASKS_SUCCESS,
  FETCH_SCHEDULED_TASKS_SUCCESS
} from '../actions/tasksActions';

export default (state = { tasks: [], hasMoreTasks: false, scheduledTasks: [] }, action) => {
  switch (action.type) {
  case START_REQUEST: {
    return {
      ...state,
      loading: true,
    };
  }
  case STOP_REQUEST: {
    return {
      ...state,
      loading: false,
    };
  }
  case FETCH_TASKS_SUCCESS: {
    return {
      ...state,
      tasks: action.tasks,
      hasMoreTasks: action.hasMoreTasks,
    };
  }
  case FETCH_SCHEDULED_TASKS_SUCCESS: {
    return {
      ...state,
      scheduledTasks: action.scheduledTasks
    };
  }
  default:
    return state;
  }
};
