import React, { useState, useEffect, useRef } from 'react';
import { isEmpty } from 'lodash';
import { Form, Input, Button, Card, message } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import config from 'react-global-configuration';

import apiClient from '../../../../../api/apiClient';
import Results from './Results';

const hcaptchaSiteKey = config.get('hcaptchaSiteKey');

export default function EmailValidator() {
  const captchaRef = useRef(null);
  const [token, setToken] = useState(undefined);
  const [email, setEmail] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState({});

  const showResults = !loading && !isEmpty(results);

  useEffect(() => {
    document.title = 'Email Validator Lookup';
  }, []);

  function resetCaptcha() {
    setToken(undefined);
    if (captchaRef.current) {
      captchaRef.current.resetCaptcha();
    }
  }

  async function onVerify(token) {
    await search(email, token);
    setToken(token);
    setEmail(undefined);
  }

  async function onSearch({ email }) {
    setToken(null);
    setEmail(email);
  }

  async function search(email, token) {
    setLoading(true);
    try {
      const { data } = await apiClient.post(`/email-validator-lookup/${email}`, { token });

      if (isEmpty(data)) {
        message.warning(`Email ${email} not found`);
        setResults({});
      } else {
        setResults(data);
      }

    } catch (error) {
      message.error(error.message);
      resetCaptcha();
    }
    setLoading(false);
  }

  return (
    <Card>
      <Form layout='inline' onFinish={onSearch} style={{ width: 370, margin: 'auto' }}>
        <Form.Item name='email' rules={[
          { required: true, message: 'Please enter an email to validate', validateTrigger: 'onSubmit' },
          { type: 'email', message: 'Please enter a valid email', validateTrigger: 'onSubmit' },
        ]}>
          <Input style={{ width: 200 }} placeholder='john@doe.com' autoFocus />
        </Form.Item>
        <Form.Item>
          <Button type='primary' htmlType='submit' loading={loading} icon={<CheckOutlined />}>
            Verify Email
          </Button>
        </Form.Item>
      </Form>
      {token === null &&
        <div style={{ marginTop: 15, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <HCaptcha
            ref={captchaRef}
            sitekey={hcaptchaSiteKey}
            onVerify={onVerify}
            onExpire={resetCaptcha}
          />
        </div>}
      {showResults && <Results value={results} />}
    </Card>
  );
}
