export default {
  apiDomain: 'api.app.outscraper.com',
  stripeApiKeys: {
    main: 'pk_live_51J0D3pCzx9P8yVtSU9tLiHuUaMzFv8J71Wtu8GXUKG2VMx6Hp63Fnpdx8ig961FRIodjzM3KwypNxhhKXAYODDvQ00BeRwsnDr',
    eu: 'pk_live_51J0D3pCzx9P8yVtSU9tLiHuUaMzFv8J71Wtu8GXUKG2VMx6Hp63Fnpdx8ig961FRIodjzM3KwypNxhhKXAYODDvQ00BeRwsnDr',
    eu2: 'pk_live_51J0D3pCzx9P8yVtSU9tLiHuUaMzFv8J71Wtu8GXUKG2VMx6Hp63Fnpdx8ig961FRIodjzM3KwypNxhhKXAYODDvQ00BeRwsnDr'
  },
  payPalClientId: 'AX72N4ZdEcc8Cx2F_9gb-CM8Lq7Y2H8eu5qtT5dwIXrmG1_PjCPU5u5dDrX4fUnLYqGmchtv96MH3LX-',
  mixpanelToken: '34ef83ffd76dbe88836281fc50d152c3',
  gaToken: 'G-1Y86YSLK68'
};
