import React from 'react';

import GoogleSearchBase from './Base';

export default function GoogleSearchImages() {
  return (
    <GoogleSearchBase
      isPerQueryLimit
      isGoogleLocationSelect
      unitName='image'
      title='Google Search Images Scraper'
      subTitle='Returns search results'
      serviceName='google_search_images_service_v2'
      queriesPlaceholder='Yaught&#10;Wallpaper&#10;Skyscraper'
      apiTag='Google/paths/~1google-search-images/get'
    />
  );
}
