import React from 'react';
import { Form, Select, Input } from 'antd';
import { useTranslation } from 'react-i18next';

import SimpleService from './Common/SimpleService';
import DomainSelectItem from './Common/Amazon/DomainSelectItem';

const { Option } = Select;

export default function AmazonProductsExtractor() {
  const { t } = useTranslation();

  return (
    <SimpleService
      title='Amazon Products Scraper'
      subTitle='Returns product listings'
      serviceName='amazon_products_service_v2'
      unitName='product'
      learnMoreUrl='https://outscraper.com/amazon-scraper'
      apiTag='Amazon/paths/~1amazon~1products/get'
      queriesLabel='Amazon ASINs or product URLs'
      queriesPlaceholder='B00K0QKBM6&#10;https://www.amazon.com/s?k=golf+clubs&ref=nb_sb_noss_1'
      defaultParams={{
        limit_per_query: 100,
        domain: 'amazon.com',
        postal_code: '',
      }}
      ExtraItems={({ updateTask, task }) => {
        const { domain, language, currency, postal_code } = task;

        return <>
          <DomainSelectItem
            value={domain}
            onChange={(domain) => updateTask({ domain })}
          />
          <br/>

          <Form.Item label={t('title.deliveryPostcode', 'Delivery postcode')} className='formItem'>
            <Input
              style={{ width: 200 }}
              placeholder='11201'
              value={postal_code}
              onChange={({ target }) => updateTask({ postal_code: target.value })}
            />
          </Form.Item><br/>

          <Form.Item label={t('title.language', 'Language')} className='formItem'>
            <Select
              showSearch
              style={{ width: 200 }}
              value={language}
              onChange={(language) => updateTask({ language })}
            >
              <Option key='en' value='en'>EN</Option>
              <Option key='de' value='de'>DE</Option>
            </Select>
          </Form.Item><br/>

          <Form.Item label={t('title.currency', 'Currency')} className='formItem'>
            <Select
              showSearch
              style={{ width: 200 }}
              value={currency}
              onChange={(currency) => updateTask({ currency })}
            >
              <Option key='USD' value='USD'>USD</Option>
              <Option key='EUR' value='EUR'>EUR</Option>
              <Option key='GBR' value='GBR'>GBR</Option>
              <Option key='CHF' value='CHF'>CHF</Option>
              <Option key='PLN' value='PLN'>PLN</Option>
              <Option key='SEK' value='SEK'>SEK</Option>
              <Option key='DKK' value='DKK'>DKK</Option>
              <Option key='CZK' value='CZK'>CZK</Option>
              <Option key='NOK' value='NOK'>NOK</Option>
            </Select>
          </Form.Item><br/>
        </>;
      }}
    />
  );
}
