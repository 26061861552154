import React from 'react';
import PropTypes from 'prop-types';

export default function Logout({ auth }) {
  auth.logout();

  return <h5>Loading...</h5>;
}

Logout.propTypes = {
  auth: PropTypes.object.isRequired,
};
