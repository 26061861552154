import React from 'react';
import { Form, Select, InputNumber, Space } from 'antd';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

import SimpleService from './Common/SimpleService';
import InfoTooltip from '../../shared/Components/InfoTooltip';
import { isTaskReady as defaultIsTaskReady } from '../../utils/utils';

const { Option } = Select;
const defaultAttributes = ['name', 'price', 'discount price', 'description', 'image', 'rating', 'reviews'];

export default function UniversalAIScraper() {
  const { t } = useTranslation();

  function isTaskReady(task) {
    return defaultIsTaskReady(task) && !isEmpty(task.attributes);
  }

  return (
    <SimpleService
      beta
      unitName='page'
      title='Universal AI-Powered Scraper'
      subTitle='Returns the data you need from any web page by using AI'
      serviceName='ai_scraper_service_v2'
      queriesLabel='Links to pages'
      queriesPlaceholder='https://www.apple.com/iphone/'
      learnMoreUrl='https://outscraper.com/universal-ai-scraper/'
      limitType={null}
      isTaskReady={isTaskReady}
      defaultParams={{ attributes: [], limit_per_query: 1 }}
      ExtraItems={({ updateTask, task }) => {
        function handleOnChange(attributes) {
          if (attributes.length > 0 && attributes[attributes.length - 1].length < 2) {
            return;
          }
          updateTask({ attributes } );
        }

        return <>
          <Form.Item required label='Attributes to parse'>
            <Select
              allowClear
              autoFocus
              showSearch
              mode='tags'
              maxTagCount={6}
              style={{ maxWidth: 800 }}
              tokenSeparators={[',']}
              placeholder='Select or enter attributes to parse'
              value={task.attributes}
              onChange={handleOnChange}
            >{defaultAttributes.map(r => <Option key={r} value={r}>{r}</Option>)}</Select>
          </Form.Item>

          <Form.Item label={t('title.pagesPerQueryLimit', 'Pages limit per one query')} className='formItem'>
            <Space align='center'>
              <InputNumber
                min={1}
                max={1000}
                step={1}
                value={task.limit_per_query}
                onChange={(v) => updateTask({limit_per_query: parseInt(v) || 1})}
              />
              <InfoTooltip
                title='Parameter sets the maximum number of pages per query, typically used in cases where the page incorporates pagination'/>
            </Space>
          </Form.Item><br/>
        </>;
      }}
    />
  );
}
