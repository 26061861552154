import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Button, notification } from 'antd';
import styled from 'styled-components';
import Mixpanel from '../../analytics/mixpanel';
import { useTranslation, Trans } from 'react-i18next';

import apiProfile from '../../api/apiProfile';

const { Text } = Typography;

const StyledText = styled(Text)`
    padding-left: 20px;
    padding-right: 20px;
`;

export default function EmailAddress({ value, verified }) {
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);
  const { t } = useTranslation();

  async function onRequest() {
    setLoading(true);

    try {
      await apiProfile.reuqestEmailVerification();
      notification.success({ message: t('title.checkInbox', 'Check Your Inbox') });
      setSent(true);
    } catch (error) {
      notification.error({ message: error.message });
    } finally {
      setLoading(false);
    }

    Mixpanel.track('Request email confirmation');
  }

  return <>
    {value}
    {verified ?
      <StyledText type='success'><Trans i18nKey='message.confirmed'>confirmed</Trans></StyledText>
      :
      <>
        <StyledText type='warning'><Trans i18nKey='message.unconfirmed'>unconfirmed</Trans></StyledText>
        {!sent &&
          <Button loading={loading} onClick={onRequest}>
            <Trans i18nKey='action.requestEmailConfirmation'>Request Email Confirmation</Trans>
          </Button>
        }
      </>
    }
  </>;
}

EmailAddress.propTypes = {
  value: PropTypes.string.isRequired,
  verified: PropTypes.bool,
};
