import React from 'react';
import PropTypes from 'prop-types';
import { Select, Space } from 'antd';

import googleLocations from '../../../../shared/data/googleLocations.json';
import InfoTooltip from '../../../../shared/Components/InfoTooltip';

const { Option } = Select;

const googleLocationsMap = {};
for (const location of googleLocations) {
  googleLocationsMap[location.country_code] = location;
}

export default function GoogleLocationSelect({ value, onChange, hideTooltip, allowClear }) {
  function handleOnChange(countryCode) {
    const rawResponse = googleLocationsMap[countryCode];
    onChange({
      region: rawResponse.country_code,
      language: rawResponse.language_code,
      google_domain: rawResponse.domain,
    });
  }

  return (
    <Space align='center'>
      <Select
        allowClear={allowClear}
        showSearch
        style={{ width: 200 }}
        value={value}
        optionFilterProp='children'
        onChange={handleOnChange}
        placeholder='Select country'
      >
        {googleLocations.map(r => <Option key={r.country_code} value={r.country_code}>{r.country_name} ({r.country_code.toUpperCase()})</Option>)}
      </Select>
      {!hideTooltip &&
        <InfoTooltip title='Parameter specifies the country to use for Google site.'/>
      }
    </Space>
  );
}

GoogleLocationSelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  hideTooltip: PropTypes.bool,
  allowClear: PropTypes.bool,
};
