import React, { useEffect, useState } from 'react';
import { Table, Empty, notification } from 'antd';
import Moment from 'react-moment';
import config from 'react-global-configuration';

import apiTasks from '../../api/apiTasks';

const LATEST_CALLS = 100;
const LATEST_HOURS = 24;

export default function WebhookCalls() {
  const [calls, setCalls] = useState([]);
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      title: 'Date and time',
      dataIndex: 'date',
      key: 'date',
      render: date => date ? <Moment local unix format='YYYY/MM/DD HH:mm:ss'>{date}</Moment> : '',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: status => <>{status}</>,
    },
  ];

  useEffect(() => {
    document.title = 'Webhook Calls | ' + config.get('company');
    onFetchData();
  }, []);

  async function onFetchData() {
    setLoading(true);
    try {
      const { data } = await apiTasks.fetchWebhookCalls(100);
      setCalls(data);
    } catch (error) {
      notification.error({ message: error.message });
    } finally {
      setLoading(false);
    }
  }

  return <>
    <h3>The Latest {LATEST_CALLS} Failed Webhook Calls During The Last {LATEST_HOURS} Hours</h3>
    <br/>

    <Table
      rowKey='id'
      size='small'
      scroll={{ y: 800 }}
      loading={loading}
      pagination={false}
      columns={columns}
      dataSource={calls}
      locale={{ emptyText: <Empty description={<>You have no failed webhook calls during the last 24 hours.</>} /> }}
    />
  </>;
}
